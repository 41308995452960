import { Box, Show, Hide } from '@telescope/cassini-ui';
import { HeaderMenu } from './HeaderMenu';
import { HeaderBanner } from './HeaderBanner';

export const Header = () => {
  return (
    <Box as="header" w="100%">
      <Hide above="lg">
        <HeaderMenu />
      </Hide>

      <Show above="lg">
        <HeaderBanner />
      </Show>
    </Box>
  );
};
