export const SnapshotType = {
  OPEN: 'open',
  PRE_VOTE_CLOSED: 'pre_vote_closed',
  POST_VOTE_CLOSED: 'post_vote_closed',
} as const;

// eslint-disable-next-line -- intentionally naming the object the same as the type
export type SnapshotType = (typeof SnapshotType)[keyof typeof SnapshotType];

export const ErrorType = {
  GENERIC: 'generic',
  OVERLIMIT: 'overlimit',
  WINDOW: 'window',
  LANGUAGE: 'language',
} as const;

// eslint-disable-next-line -- intentionally naming the object the same as the type
export type ErrorType = (typeof ErrorType)[keyof typeof ErrorType];

export type VoteOption = {
  image: string;
  name: string;
  headline: string;
  description: string;
};
