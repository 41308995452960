import { ReactElement, createContext, useState } from 'react';
import { GenericObject } from 'types';
import { getLanguageSuffix } from 'utils';

const supportedLanguages = ['en', 'ja', 'es', 'esc', 'fr', 'ar', 'de', 'it', 'pt'] as const;

type Language = (typeof supportedLanguages)[number];

export type LanguageOption = {
  label: string;
  code: string;
  id: Language;
};

const defaultLanguage = getLanguageSuffix(navigator.language);

export const DataContext = createContext<{
  language: Language;
  languageCode: string;
  changeLanguage: (newLang: LanguageOption) => void;
}>(undefined!);

export const DataProvider = ({ children }: { children: React.ReactNode }): ReactElement<string, string> => {
  const [language, setLanguage] = useState<Language>(defaultLanguage);
  const [languageCode, setLanguageCode] = useState<string>(defaultLanguage);

  const provider = {
    language,
    languageCode,
    changeLanguage: (newLang: LanguageOption) => {
      setLanguage(newLang.id);
      setLanguageCode(newLang.code);
    },
  };

  return <DataContext.Provider value={provider}>{children}</DataContext.Provider>;
};
