import { Box, Button, Flex, Heading, Image, SimpleGrid, Text } from '@telescope/cassini-ui';
import { UserRole, useAuth, useResubmitStore, useVotingGrid } from 'features';
import { useSegment } from 'features/analytics/useSegment';
import parse from 'html-react-parser';
import { DataContext, UseWidgetResponse, useWidget } from 'providers';
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { SnapshotType } from 'types';
import { ROUTES } from 'utils';
import { CategoriesList } from './CategoriesList';
import reactStringReplace from 'react-string-replace';
import useShareStore from 'features/share/share-store';

const ResubmitCTA = (props: { copy: string; onCtaClick: () => void }) => {
  const { copy, onCtaClick } = props;

  // parse the copy to find the button text and the rest of the text. Button text must be delimited by {{ }}
  const el = reactStringReplace(copy, /{{(.*?)}}/, match => (
    <Button variant="link" fontWeight="bold" color="primaryBase" onClick={onCtaClick} minH="auto">
      {match}
    </Button>
  ));

  return <Text mb={12}>{el}</Text>;
};

export const CategoriesGrid = () => {
  const { pageEvent } = useSegment();
  const { showReviewSubmission, setPendingAuth, isPendingAuth } = useResubmitStore();
  const { language } = useContext(DataContext);
  const { data: copy } = useWidget({
    select: (data: UseWidgetResponse) => {
      const text = data.snapshot.text;
      return {
        open: text.voting_home_page,
        open_all_categories_voted: text.home_page_all_categories_voted,
        pre_vote_closed: text.pre_vote_closed,
        post_vote_closed: text.post_vote_closed,
      };
    },
  });
  const { data: snapshotType } = useWidget({
    select: (data: UseWidgetResponse) => data.snapshot.text.snapshot_settings.snapshot_type,
  });

  const navigate = useNavigate();
  const { hasPreviousBallot, votedCategories, areAllCategoriesVoted, nextVoteableCategory } = useVotingGrid();
  const { role, isAuthenticated } = useAuth();
  const { toggleShare } = useShareStore();
  const startVotingBtnText =
    votedCategories.length > 0
      ? copy.open.resume_voting_button_text[language]
      : copy.open.start_voting_button_text[language];
  const resubmitCtaText = isAuthenticated
    ? copy.open.cta_authenticated[language]
    : copy.open.cta_unauthenticated[language];
  const isAudienceRole = role === UserRole.AUDIENCE;
  const isPostVote = snapshotType === SnapshotType.POST_VOTE_CLOSED;
  const showStartVotingButton = !isPostVote && !areAllCategoriesVoted;
  const disclaimerText = parse(copy.open[`${role}_disclaimer`][language]);
  const showShareVotesButton = !isPostVote && areAllCategoriesVoted && isAudienceRole;

  const shouldShowResubmitCTA = () => {
    if (!isAudienceRole || isPostVote) return false;

    if (!isAuthenticated) {
      return true;
    }

    return hasPreviousBallot;
  };
  const getCopy = () => {
    if (isPostVote) {
      return {
        headline: copy.post_vote_closed.headline[language],
        description: copy.post_vote_closed.description[language],
      };
    }

    if (areAllCategoriesVoted) {
      return {
        headline: copy.open_all_categories_voted[`${role}_headline`][language],
        description: copy.open_all_categories_voted[`${role}_description`][language],
      };
    }

    return {
      headline: copy.open[`${role}_headline`][language],
      description: copy.open[`${role}_description`][language],
    };
  };

  const { headline, description } = getCopy();
  const showResubmitCTA = shouldShowResubmitCTA();

  const handleResubmitClick = () => {
    // 1. If user is logged in, check if they are able to resubmit (they have a previous ballot)
    // 2. If user is not logged in, trigger login modal
    // 3. If user is logged in but does not have a previous ballot, take them to the first category
    if (!isAuthenticated) {
      setPendingAuth(true);
      return navigate(ROUTES.AUTH, { state: { backgroundLocation: ROUTES.HOME } });
    }

    showReviewSubmission('direct_click');
  };

  const handleStartVoting = () => {
    navigate(`/${nextVoteableCategory.slug}`);
  };

  useEffect(() => {
    if (isAuthenticated && hasPreviousBallot && isPendingAuth) {
      setPendingAuth(false);
      showReviewSubmission('direct_click');
    }
  }, [hasPreviousBallot, isAuthenticated, showReviewSubmission, isPendingAuth, setPendingAuth]);

  useEffect(() => {
    pageEvent('Main Landing');
  }, [pageEvent]);

  return (
    <Box as="section">
      <Flex
        direction="column"
        alignItems={{ base: 'center', md: 'flex-start' }}
        mb={{ base: 12, lg: 16 }}
        textAlign={'center'}
      >
        <Image
          maxW={{ base: '180px', md: '242px' }}
          src="https://ts-cms-production.votenow.tv/campaign/10/12/1012927/1698353626653ad1dacb9313.20760642.png"
          alt="Anime Awards Crunchyroll Logo"
          mb={6}
        />

        <Heading
          fontFamily="serifHeading"
          textAlign={{ base: 'center', md: 'left' }}
          fontSize={{ base: '28px', md: '54px' }}
          mb={3}
        >
          {parse(headline)}
        </Heading>

        <Text textAlign={{ base: 'center', md: 'left' }} mb={{ base: 6, md: 12 }}>
          {parse(description)}
        </Text>

        {showResubmitCTA && <ResubmitCTA copy={resubmitCtaText} onCtaClick={handleResubmitClick} />}

        {showStartVotingButton && (
          <Button variant="primary" onClick={handleStartVoting}>
            {startVotingBtnText}
          </Button>
        )}
        {showShareVotesButton && (
          <Button variant="primary" onClick={toggleShare}>
            {copy.open_all_categories_voted.share_button[language]}
          </Button>
        )}
      </Flex>

      <SimpleGrid w="100%" columns={{ base: 1, md: 2, lg: 4 }} columnGap={7} rowGap={4} gridAutoRows={'1fr'}>
        <CategoriesList />
      </SimpleGrid>
      {!isPostVote && (
        <Text mt={12} fontSize="xs">
          {disclaimerText}
        </Text>
      )}
    </Box>
  );
};
