import { useBreakpointValue, useDisclosure, VoteAPIResponseCode } from '@telescope/cassini-hooks';
import {
  Box,
  Button,
  ButtonGroup,
  ButtonProps,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerOverlay,
  Flex,
  Heading,
  Image,
  Link,
  LinkProps,
  Text,
  VStack,
} from '@telescope/cassini-ui';
import { ChevronLeft, PlayVideoIcon } from 'components';
import { useAuth, useErrorStore, useResubmitStore, useSubmitVote, useVoteHistory } from 'features';
import parse from 'html-react-parser';
import { DataContext, useWidget, UseWidgetResponse } from 'providers';
import { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ErrorType } from 'types';
import { ROUTES } from 'utils';
import { useVotingGrid } from '../providers';
import { SnapshotType } from 'types';
import { useSegment } from 'features/analytics/useSegment';

type WhereToWatchButtonProps = ButtonProps & LinkProps;
const WhereToWatchButton = (props: WhereToWatchButtonProps) => {
  return (
    <Button
      aria-label="Watch"
      variant="ghost"
      as={Link}
      target="_blank"
      rounded={'md'}
      fontWeight={500}
      fontSize={'xs'}
      height="auto"
      p={0}
      _hover={{ textDecoration: 'none' }}
      {...props}
    >
      <VStack>
        <PlayVideoIcon />
        <Text color="text.primary">{props.children}</Text>
      </VStack>
    </Button>
  );
};

export const NomineeDetail = () => {
  const { data } = useWidget({ select: (data: UseWidgetResponse) => data.snapshot.text.nominee_detail });
  const { language } = useContext(DataContext);
  const { data: voteHistory } = useVoteHistory();
  const hasPreviousBallot = Object.keys(voteHistory.previousHistory).length > 0;
  const { submitVote, isLoading: isSubmitting } = useSubmitVote();
  const { showError } = useErrorStore();

  const { activeCategory, activeVoteOption: voteOption } = useVotingGrid();
  const { user, role } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const categoryVotedOption = voteHistory.history[activeCategory.id];
  const voted = categoryVotedOption === voteOption.id;
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });
  const isMobile = useBreakpointValue({ base: true, md: false }, { ssr: false });
  const { hasBeenDismissed, hasBeenSubmitted, showReviewSubmission } = useResubmitStore();
  const voteButtonText = categoryVotedOption
    ? voted
      ? data.voted_button_text[language]
      : data.vote_button_text[language]
    : data.vote_button_text[language];
  const watchButtonText = data.watch_button_text[language];
  const disclaimerText = parse(data[`${role}_disclaimer`][language]);

  const { data: snapshotType } = useWidget({
    select: (data: UseWidgetResponse) => data.snapshot.text.snapshot_settings.snapshot_type,
  });
  const isPostVote = snapshotType === SnapshotType.POST_VOTE_CLOSED;
  const { trackVote } = useSegment();

  if (!voteOption) {
    return null;
  }

  const handleVote = async () => {
    if (!user) {
      navigate(ROUTES.AUTH, { state: { backgroundLocation: location } });
      return;
    }

    if (hasPreviousBallot && !hasBeenDismissed && !hasBeenSubmitted) {
      showReviewSubmission('vote_flow', { onResubmit: () => navigate(ROUTES.HOME) });
      return;
    }

    const payload = {
      category: voteOption.catId,
      contestant: voteOption.id,
    };

    try {
      const response = await submitVote(payload);

      trackVote(activeCategory.name);

      // TODO: Handle response codes
    } catch (err) {
      const errors = {
        [VoteAPIResponseCode.WINDOW]: ErrorType.WINDOW,
        [VoteAPIResponseCode.OVERLIMIT]: ErrorType.OVERLIMIT,
        [VoteAPIResponseCode.GENERAL_INVALID]: ErrorType.GENERIC,
      };

      // @ts-expect-error: res.response_code might not be a property of errors
      // In this case use the known property VoteAPIResponseCode.GENERAL_INVALID
      const error = errors[err] || errors[VoteAPIResponseCode.GENERAL_INVALID];
      showError(error);
    }
  };

  // Mobile Layout - Displayed in a Drawer
  if (isMobile) {
    return (
      <Drawer isOpen={isOpen} placement="right" onClose={onClose} onCloseComplete={() => navigate('..')} size="full">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton>
            <ChevronLeft />
          </DrawerCloseButton>
          <DrawerBody>
            <Flex
              direction="column"
              gap={{ base: 5, lg: 16 }}
              alignItems={{ base: 'center', md: 'revert' }}
              textAlign={{ base: 'center', md: 'left' }}
              mb={12}
            >
              <Box className="NomineeDetail__img-container" w="100%" maxW="470px">
                <Image className="NomineeDetail__img" src={voteOption.image} m="auto" />
              </Box>
              <Flex
                data-vote-option-name={voteOption.name}
                className="NomineeDetail__details-container"
                direction="column"
                w="100%"
              >
                <VStack
                  m="auto"
                  mb={20}
                  spacing={3}
                  w={{ base: '85%', md: '100%' }}
                  textAlign={{ base: 'center', md: 'left' }}
                  align={{ base: 'center', md: 'flex-start' }}
                >
                  <Text className="NomineeDetail__category-name" color="text.subtle" size="small" fontWeight={500}>
                    {parse(activeCategory[`name_${language}`])}
                  </Text>

                  <Heading
                    className="NomineeDetail__heading"
                    as="h1"
                    fontSize="20px"
                    textAlign="center"
                    fontWeight={700}
                  >
                    {parse(voteOption[`name_${language}`])}
                  </Heading>

                  <Text
                    className="NomineeDetail__secondary-name"
                    size="small"
                    fontWeight={500}
                    _empty={{ display: 'none' }}
                  >
                    {parse(voteOption[`additional_name_${language}`])}
                  </Text>

                  <Text className="NomineeDetail__studio-name" size="small" fontWeight={'500'}>
                    {parse(voteOption[`studio_name_${language}`])}
                  </Text>

                  {voteOption[`description_${language}`] && (
                    <Text
                      className="NomineeDetail__description"
                      size="medium"
                      _empty={{ display: 'none' }}
                      fontWeight={'500'}
                    >
                      {parse(voteOption[`description_${language}`])}
                    </Text>
                  )}

                  {voteOption[`copyright_${language}`] && (
                    <Text className="NomineeDetail__copyright" size="extraSmall" color="text.subtle" fontWeight={'500'}>
                      {parse(voteOption[`copyright_${language}`])}
                    </Text>
                  )}
                </VStack>
              </Flex>
            </Flex>
          </DrawerBody>

          <DrawerFooter flexDirection="column" gap={2}>
            {!isPostVote && (
              <>
                <Button
                  className="NomineeDetail__vote-button"
                  variant={'primary'}
                  isDisabled={!!categoryVotedOption}
                  onClick={() => handleVote()}
                  isLoading={isSubmitting}
                  w="full"
                >
                  {parse(voteButtonText)}
                </Button>

                <Text
                  className="NomineeDetail__disclaimer"
                  color="text.subtle"
                  textAlign={{ base: 'center', lg: 'left' }}
                  size="extraSmall"
                  maxW="330px"
                  mb={2}
                >
                  {disclaimerText}
                </Text>
              </>
            )}
            {watchButtonText && data.where_to_watch_urls[language] && (
              <WhereToWatchButton href={data.where_to_watch_urls[language]}>{watchButtonText}</WhereToWatchButton>
            )}
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    );
  }

  // Tablet and Desktop Layout
  return (
    <Flex
      hideBelow={'md'}
      direction={{ base: 'column-reverse', md: 'row' }}
      gap={{ base: 5, lg: 16 }}
      alignItems={{ base: 'center', md: 'revert' }}
      textAlign={{ base: 'center', md: 'left' }}
      mb={12}
    >
      <Flex
        data-vote-option-name={voteOption.name}
        className="NomineeDetail__details-container"
        direction="column"
        w="100%"
      >
        <VStack
          m="auto"
          mb={20}
          spacing={3}
          w={{ base: '85%', md: '100%' }}
          textAlign={{ base: 'center', md: 'left' }}
          align={{ base: 'center', md: 'flex-start' }}
        >
          <Text className="NomineeDetail__category-name" color="text.subtle" size="small" fontWeight={500}>
            {parse(activeCategory[`name_${language}`])}
          </Text>

          <Heading className="NomineeDetail__heading" as="h1" size="medium" textAlign="initial" fontWeight={700}>
            {parse(voteOption[`name_${language}`])}
          </Heading>

          <Text className="NomineeDetail__secondary-name" size="small" fontWeight={500} _empty={{ display: 'none' }}>
            {parse(voteOption[`additional_name_${language}`])}
          </Text>

          <Text className="NomineeDetail__studio-name" size="small" fontWeight={'500'}>
            {parse(voteOption[`studio_name_${language}`])}
          </Text>

          {voteOption[`description_${language}`] && (
            <Text className="NomineeDetail__description" size="medium" _empty={{ display: 'none' }}>
              {parse(voteOption[`description_${language}`])}
            </Text>
          )}

          {voteOption[`copyright_${language}`] && (
            <Text className="NomineeDetail__copyright" size="extraSmall" color="text.subtle">
              {parse(voteOption[`copyright_${language}`])}
            </Text>
          )}
        </VStack>

        <ButtonGroup mb={2} spacing={3}>
          {!isPostVote && (
            <Button
              className="NomineeDetail__vote-button"
              variant={'primary'}
              isDisabled={!!categoryVotedOption}
              onClick={() => handleVote()}
              isLoading={isSubmitting}
            >
              {parse(voteButtonText)}
            </Button>
          )}
          {watchButtonText && data.where_to_watch_urls[language] && (
            <WhereToWatchButton href={data.where_to_watch_urls[language]}>{watchButtonText}</WhereToWatchButton>
          )}
        </ButtonGroup>
        {!isPostVote && (
          <Text
            className="NomineeDetail__disclaimer"
            color="text.subtle"
            textAlign={{ base: 'center', lg: 'left' }}
            size="extraSmall"
            maxW="330px"
            mx={{ base: 'auto', lg: 0 }}
          >
            {disclaimerText}
          </Text>
        )}
      </Flex>

      <Box className="NomineeDetail__img-container" w="100%" maxW="470px">
        <Image className="NomineeDetail__img" src={voteOption.image} m="auto" />
      </Box>
    </Flex>
  );
};
