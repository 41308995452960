import {
  Box,
  Button,
  Container,
  Flex,
  Grid,
  GridItem,
  HStack,
  Heading,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
} from '@telescope/cassini-ui';
import { ArrowDownIcon, ChevronLeft, ChevronRight, InfoIcon } from 'components';
import { useVotingGrid } from 'features/voting-app';
import parse from 'html-react-parser';
import { DataContext, UseWidgetResponse, useWidget } from 'providers';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { SnapshotType } from 'types';
import { slugify } from 'utils';
import { CategoriesMenu } from './CategoriesMenu';

export const CategoryNavigation = () => {
  const navigate = useNavigate();
  const { language } = useContext(DataContext);
  const { data } = useWidget({ select: (data: UseWidgetResponse) => data.snapshot.text.categories_navigation });
  const { activeCategory, nextCategory, previousCategory, allowedCategories, votedCategories } = useVotingGrid();
  const voteProgressText = `${data.vote_progress[language]} ${votedCategories.length} / ${allowedCategories.length} `;
  const { data: snapshotType } = useWidget({
    select: (data: UseWidgetResponse) => data.snapshot.text.snapshot_settings.snapshot_type,
  });
  const isPostVote = snapshotType === SnapshotType.POST_VOTE_CLOSED;

  const handlePrev = () => {
    navigate(`../${slugify(previousCategory.name)}`);
  };

  const handleNext = () => {
    navigate(`../${slugify(nextCategory.name)}`);
  };

  return (
    <>
      {/* Tablet/Desktop Layout */}
      <Box hideBelow="md" bg="CategoryNavigation.root.bg" w="full">
        <Container>
          <Grid gridTemplateColumns={'repeat(6,1fr)'} alignItems="center">
            <GridItem>
              <Button
                variant="secondary"
                leftIcon={<ChevronLeft />}
                size={'sm'}
                aria-label="Navigate to previous category"
                onClick={handlePrev}
              >
                {data.previous_category_label[language]}
              </Button>
            </GridItem>
            <GridItem hideBelow="lg" colStart={3} colSpan={2} justifySelf="center">
              <CategoriesMenu
                Trigger={
                  <Button
                    variant={'secondary'}
                    rightIcon={<ArrowDownIcon />}
                    size="sm"
                    aria-label="Navigate to all categories list"
                  >
                    {data.all_categories_label[language]}
                  </Button>
                }
              />
            </GridItem>
            <GridItem colStart={{ md: 3, lg: undefined }} colSpan={{ md: 2, lg: undefined }} justifySelf="center">
              {!isPostVote && (
                <Text as="span" fontSize="sm" fontWeight="700">
                  {voteProgressText}
                </Text>
              )}
            </GridItem>
            <GridItem justifySelf="end" colEnd={-1}>
              <Button
                variant="secondary"
                rightIcon={<ChevronRight />}
                size="sm"
                aria-label="Navigate to next category"
                onClick={handleNext}
              >
                {data.next_category_label[language]}
              </Button>
            </GridItem>
          </Grid>
        </Container>
      </Box>

      {/* Mobile Layout */}
      <Box hideFrom={'md'} bg="CategoryNavigation.root.bg" w="full">
        <Container>
          {!isPostVote && (
            <HStack justifyContent="center" mb={2}>
              <Text as="span" fontSize="sm" fontWeight="700">
                {voteProgressText}
              </Text>
            </HStack>
          )}

          <Flex display="flex" alignItems="center" justifyContent={'space-between'}>
            <Box>
              <IconButton
                variant={'secondary'}
                icon={<ChevronLeft />}
                aria-label="Previous Category"
                onClick={handlePrev}
                border="none"
                shadow="md"
              />
            </Box>
            {activeCategory && (
              <Flex justify="center" alignItems="center">
                <Heading as="h1" size={{ base: 'sm', sm: 'md' }} fontWeight="700">
                  {parse(activeCategory[`name_${language}`])}
                </Heading>

                <Popover placement="bottom-start">
                  <PopoverTrigger>
                    <IconButton size={'sm'} icon={<InfoIcon />} aria-label="Category Information" variant="unstyled" />
                  </PopoverTrigger>
                  <PopoverContent bg="trout" color="white">
                    <PopoverArrow bg="trout" />
                    <PopoverBody>
                      <Text>{activeCategory[`description_${language}`]}</Text>
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              </Flex>
            )}
            <Flex>
              <IconButton
                variant="secondary"
                icon={<ChevronRight />}
                aria-label="Next Category"
                onClick={handleNext}
                shadow="md"
              ></IconButton>
            </Flex>
          </Flex>
        </Container>
      </Box>
    </>
  );
};
