import { ComponentStyleConfig } from '@chakra-ui/react';

export const dividerTheme: ComponentStyleConfig = {
  variants: {
    divider: {
      borderColor: '#4A4E58',
      opacity: '1',
      margin: 5,
    },
  },
};
