import { VoteAPIResponseCode } from '@telescope/cassini-hooks';
import {
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Radio,
  RadioGroup,
  Stack,
  Text,
  VStack,
} from '@telescope/cassini-ui';
import { PhoneNumberField } from 'components';
import { useAuth } from 'features/auth';
import { useErrorStore } from 'features/error';
import parse from 'html-react-parser';
import { DataContext, UseWidgetResponse, useWidget } from 'providers';
import { MutableRefObject, useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { ErrorType } from 'types';
import { EMAIL_REGEX } from 'utils';
import { validatePhone } from '../utils/validations';
import { useSegment } from 'features/analytics/useSegment';

type AudienceLoginMethod = 'phone' | 'email';

type AudienceLoginFormValues = {
  method: AudienceLoginMethod;
  user_id: string;
  optin_1: boolean;
  optin_2: boolean;
};

const defaultValues: AudienceLoginFormValues = {
  method: 'email',
  user_id: '',
  optin_1: false,
  optin_2: false,
};

export const AudienceLogin = () => {
  const { data } = useWidget({ select: (data: UseWidgetResponse) => data.snapshot.text.login });

  const { language } = useContext(DataContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const { login } = useAuth();
  const { showError } = useErrorStore();
  const { identifyUser } = useSegment();

  const {
    control,
    reset,
    formState: { isDirty, errors },
    handleSubmit,
    watch,
  } = useForm<AudienceLoginFormValues>({
    defaultValues,
  });
  const watchMethod = watch('method');

  // reset form when method changes
  useEffect(() => {
    reset({
      ...defaultValues,
      method: watchMethod,
    });
  }, [watchMethod, reset]);

  const renderCheckboxes = (method: AudienceLoginMethod) => {
    return data[`${method}_optins`].map((optin: any) => {
      if (!optin || !optin.name) return null;

      const label = optin.copy[language] || '';
      const isRequired = optin.required === 'true';
      const errorMessage = isRequired ? optin.error_message[language] : false;
      const name = optin.name as keyof AudienceLoginFormValues;

      return (
        <Controller
          name={optin.name}
          key={optin.name}
          control={control}
          rules={{ required: errorMessage }}
          defaultValue={false}
          render={({ field: { onChange, value, ref }, fieldState }) => {
            return (
              <FormControl isInvalid={fieldState.invalid}>
                <Checkbox
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.checked)}
                  isChecked={value}
                  ref={ref}
                  sx={{ '.chakra-checkbox__control': { borderColor: 'secondary.400', backgroundColor: '#192E38' } }}
                >
                  <Text color="text.muted">{parse(label)}</Text>
                </Checkbox>
                {errors[name] && (
                  <Text color="text.error" size="small">
                    {errors[name]?.message}
                  </Text>
                )}
              </FormControl>
            );
          }}
        />
      );
    });
  };

  const submitLogin = async (formData: AudienceLoginFormValues) => {
    try {
      setIsSubmitting(true);

      // remove all whitespace from user_id
      const user_id = formData.user_id.replace(/\s/g, '');
      const user = await login({ ...formData, user_id });

      if (user) {
        identifyUser(user);
        setIsSubmitting(false);
        navigate(-1);
      }
    } catch (error) {
      setIsSubmitting(false);
      const errors = {
        [VoteAPIResponseCode.WINDOW]: ErrorType.WINDOW,
        [VoteAPIResponseCode.GENERAL_INVALID]: ErrorType.GENERIC,
      };
      // @ts-expect-error: res.response_code might not be a property of errors
      const err = errors[error] || errors[VoteAPIResponseCode.GENERAL_INVALID];
      showError(err);
    }
  };

  return (
    <Flex
      as="form"
      id="loginForm"
      direction="column"
      alignItems="center"
      maxW="container.sm"
      onSubmit={handleSubmit(submitLogin)}
    >
      <Heading size="medium" mb={4} color="white">
        {data.audience_headline[language]}
      </Heading>
      <Controller
        name="method"
        control={control}
        rules={{
          required: data.audience_error_message[language],
        }}
        render={({ field, fieldState }) => {
          return (
            <RadioGroup {...field}>
              <Stack direction="row" spacing={4} mb={6}>
                <Radio value="email">{data.email_radio_button_text[language]}</Radio>
                <Radio value="phone">{data.phone_number_radio_button_text[language]}</Radio>
              </Stack>
            </RadioGroup>
          );
        }}
      />

      {watchMethod === 'email' ? (
        <Controller
          name="user_id"
          control={control}
          defaultValue=""
          rules={{
            required: data.audience_error_message[language],
            pattern: {
              value: new RegExp(EMAIL_REGEX, 'i'),
              message: data.audience_error_message[language],
            },
          }}
          render={({ field: { onChange, value, onBlur }, fieldState }) => {
            return (
              <FormControl isInvalid={fieldState.invalid} mb={6}>
                <Input
                  data-peer
                  name="email"
                  placeholder=""
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.value.toLowerCase().trim())}
                  onBlur={(e: React.FocusEvent<HTMLInputElement>) => e.target.value.trim()}
                  value={value}
                  variant={'flushed'}
                  maxLength={40}
                  focusBorderColor="primaryBase"
                  borderBottomWidth="2px"
                />
                <FormLabel variant="floating" size="lg" htmlFor="email">
                  {data.audience_placeholder[language]}
                </FormLabel>
                {errors.user_id && (
                  <Text color="text.error" fontSize="sm">
                    {errors.user_id.message}
                  </Text>
                )}
              </FormControl>
            );
          }}
        />
      ) : (
        <Controller
          name="user_id"
          control={control}
          defaultValue=""
          rules={{
            required: data.audience_error_message_phone[language],
            validate: (value, formValues) =>
              validatePhone(value, formValues) || data.audience_error_message_phone[language],
          }}
          render={({ field, fieldState }) => {
            const { ref, ...rest } = field;
            return (
              <FormControl isInvalid={fieldState.invalid} mb={6}>
                <FormLabel variant="floating" size="lg" htmlFor="phone"></FormLabel>
                <PhoneNumberField inputRef={ref as unknown as MutableRefObject<HTMLInputElement>} {...rest} />
                {errors.user_id && (
                  <Text color="text.error" fontSize="sm">
                    {errors.user_id.message}
                  </Text>
                )}
              </FormControl>
            );
          }}
        />
      )}
      <VStack spacing={6} alignItems="left" mb={14}>
        {renderCheckboxes(watchMethod)}
      </VStack>
      <Button
        variant="primary"
        size={['sm', 'md']}
        px={14}
        isLoading={isSubmitting}
        form="loginForm"
        type="submit"
        disabled={!isDirty || isSubmitting}
      >
        {data.button_text[language]}
      </Button>
    </Flex>
  );
};
