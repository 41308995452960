import { VoteAPIResponseCode } from '@telescope/cassini-hooks';
import { Button, Checkbox, Flex, FormControl, FormLabel, Heading, Input, Text, VStack } from '@telescope/cassini-ui';
import { useAuth } from 'features/auth';
import { useErrorStore } from 'features/error';
import parse from 'html-react-parser';
import { DataContext, UseWidgetResponse, useWidget } from 'providers';
import { FormEvent, useContext, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { ErrorType } from 'types';

type JudgeLoginMethod = 'code';

type JudgeLoginFormValues = {
  method: JudgeLoginMethod;
  user_id: string;
  optin_1: boolean;
  optin_2: boolean;
};

export const JudgeLogin = () => {
  const { data } = useWidget({ select: (data: UseWidgetResponse) => data.snapshot.text.login });
  const { language } = useContext(DataContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const { showError } = useErrorStore();

  const {
    control,
    formState: { isDirty, errors },
    handleSubmit,
    setError,
  } = useForm<JudgeLoginFormValues>({ defaultValues: { method: 'code', user_id: '', optin_1: false, optin_2: false } });

  const renderCheckboxes = (method: JudgeLoginMethod) => {
    return data[`${method}_optins`].map((optin: any) => {
      if (!optin || !optin.name) return null;

      const label = optin.copy[language] || '';
      const isRequired = optin.required === 'true';
      const errorMessage = isRequired ? optin.error_message[language] : false;
      const name = optin.name as keyof JudgeLoginFormValues;

      return (
        <Controller
          name={optin.name}
          key={optin.name}
          control={control}
          rules={{ required: errorMessage }}
          defaultValue={false}
          render={({ field: { onChange, value, ref }, fieldState }) => {
            return (
              <FormControl isInvalid={fieldState.invalid}>
                <Checkbox
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.checked)}
                  checked={value}
                  ref={ref}
                  sx={{ '.chakra-checkbox__control': { borderColor: 'secondary.400', backgroundColor: '#192E38' } }}
                >
                  <Text color="text.muted">{parse(label)}</Text>
                </Checkbox>
                {errors[name] && (
                  <Text color="text.error" size="small">
                    {errors[name]?.message}
                  </Text>
                )}
              </FormControl>
            );
          }}
        />
      );
    });
  };

  const { login } = useAuth();

  const submitLogin = (e: FormEvent) => {
    e.preventDefault();
    handleSubmit(async (formData: JudgeLoginFormValues) => {
      try {
        setIsSubmitting(true);
        formData.user_id = formData.user_id.trim();
        const user = await login(formData);

        if (user) {
          setIsSubmitting(false);
          navigate(-1);
        }
      } catch (error) {
        setIsSubmitting(false);
        if (error === VoteAPIResponseCode.WINDOW) {
          return showError(ErrorType.WINDOW);
        }
        return setError('user_id', {
          type: 'invalid',
          message: data.judge_error_message[language],
        });
      }
    })();
  };

  return (
    <Flex as="form" id="loginForm" direction="column" alignItems="center" maxW="container.sm" onSubmit={submitLogin}>
      <Heading size="medium" mb={12}>
        {data.judge_headline[language]}
      </Heading>

      <Controller
        name="user_id"
        control={control}
        defaultValue=""
        rules={{
          required: data.judge_error_message[language],
        }}
        render={({ field: { onChange, value }, fieldState }) => {
          return (
            <FormControl isInvalid={fieldState.invalid} mb={6}>
              <Input
                data-peer
                name="code"
                placeholder=""
                onChange={onChange}
                onBlur={(e: React.FocusEvent<HTMLInputElement>) => e.target.value.trim().toLowerCase()}
                value={value}
                maxLength={255}
                variant="flushed"
                focusBorderColor="primaryBase"
                borderBottomWidth="2px"
              />
              <FormLabel variant="floating" size="lg" htmlFor="code">
                {data.judge_placeholder[language]}
              </FormLabel>
              {errors.user_id && (
                <Text color="text.error" fontSize="sm">
                  {errors.user_id.message}
                </Text>
              )}
            </FormControl>
          );
        }}
      />
      <VStack spacing={6} alignItems="left" mb={14}>
        {renderCheckboxes('code')}
      </VStack>

      <Button
        variant="primary"
        size={['sm', 'md']}
        px={14}
        isLoading={isSubmitting}
        form="loginForm"
        type="submit"
        disabled={!isDirty || isSubmitting}
      >
        {data.button_text[language]}
      </Button>
    </Flex>
  );
};
