import { VoteApiProvider } from '@telescope/cassini-hooks';
import { CassiniProvider, Container, Spinner, LightMode } from '@telescope/cassini-ui';
import { AuthProvider, UserRole } from 'features';
import { DataContext, StorageProvider, useWidget, UseWidgetResponse } from 'providers';
import { Suspense, useContext, useEffect } from 'react';
import { customTheme } from 'themes';
import { getTextDirection, getWidgetId, VERSION_CHECK } from 'utils';
import TagManager from 'react-gtm-module';
import { ModalsProvider } from 'components';

const wid = getWidgetId();

export const AppProvider = ({ children }: { children: React.ReactNode }) => {
  const {
    data: { gtm_container_id, ...settings },
  } = useWidget({
    select: (data: UseWidgetResponse) => data.snapshot.settings,
  });

  const { language } = useContext(DataContext);
  const direction = getTextDirection(language);

  // Initialize Google Tag Manager
  useEffect(() => {
    TagManager.initialize({
      gtmId: gtm_container_id as string,
    });
  }, [gtm_container_id]);

  const userRole = window.USER_ROLE || UserRole.AUDIENCE;

  return (
    <StorageProvider prefix={`${wid}:`}>
      <CassiniProvider theme={customTheme({ direction })}>
        <Suspense
          fallback={
            <Container centerContent>
              <Spinner />
            </Container>
          }
        >
          <ModalsProvider>
            <VoteApiProvider
              settings={{
                apiKey: settings[`apiKey_${userRole}`],
                versionId: `${settings[`version_id_${userRole}`]}${VERSION_CHECK[userRole]}`,
              }}
              options={{ authType: 2 }}
            >
              <AuthProvider role={userRole}>{children}</AuthProvider>
            </VoteApiProvider>
          </ModalsProvider>
        </Suspense>
      </CassiniProvider>
    </StorageProvider>
  );
};
