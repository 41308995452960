import { Link, LinkProps } from '@telescope/cassini-ui';
import { CheckmarkFilledIcon } from 'components';
import { useVoteHistory } from 'features/voting-app';
import parse from 'html-react-parser';
import { DataContext } from 'providers';
import { useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { ICategory } from 'types';
import { slugify } from 'utils';

interface CategoryButtonProps extends LinkProps {
  category: ICategory;
}

export const CategoryButton = ({ category, ...rest }: CategoryButtonProps) => {
  const { language } = useContext(DataContext);
  const { data: voteHistory } = useVoteHistory();
  const slug = slugify(category.name_en);
  const isCategoryVoted = !!voteHistory.history[category.id];

  return (
    <Link
      as={RouterLink}
      to={`/${slug}`}
      fontSize={{ base: 'sm', lg: 'xs' }}
      w="100%"
      borderRadius="full"
      textAlign={{ base: 'left' }}
      bg={isCategoryVoted ? 'CategoryButton.bg--completed' : 'CategoryButton.bg'}
      color={isCategoryVoted ? 'CategoryButton.text--completed' : 'CategoryButton.text'}
      px={[6, 4]}
      py={2}
      minH={{ base: '52px' }}
      fontWeight="700"
      className="CategoryButton"
      data-category-name={category.name}
      display="flex"
      justifyContent={{ base: 'flex-start' }}
      alignItems="center"
      _hover={{ textDecoration: 'none', bg: 'CategoryButton.bg--hover', color: 'CategoryButton.text--hover' }}
      {...rest}
    >
      {isCategoryVoted && <CheckmarkFilledIcon mr={2} />}
      {parse(category[`name_${language}`])}
    </Link>
  );
};
