import { useMutation } from '@tanstack/react-query';
import { VoteAPIResponseCode, useVoteApi } from '@telescope/cassini-hooks';
import { useAuth } from 'features';
import { UseWidgetResponse, useWidget } from 'providers';
import { ACTION_TYPES, VERSION_CHECK } from 'utils';

export const useTrackDownloads = () => {
  const { user } = useAuth();
  const {
    data: { apiKey_ugc, version_id_ugc },
  } = useWidget({ select: (data: UseWidgetResponse) => data.snapshot.settings });

  const vote = useVoteApi(
    {
      apiKey: apiKey_ugc,
      versionId: `${version_id_ugc}${VERSION_CHECK.ugc}`,
    },
    { authType: 2 },
  );

  const { mutateAsync, ...rest } = useMutation({
    mutationFn: async (payload: { orientation: 'horizontal' | 'vertical' }) => {
      const response = await vote({
        method: user?.user.method,
        user_id: user?.user.user_id,
        action_type: ACTION_TYPES.TRACK_UGC_DOWNLOADS,
        v: payload.orientation,
      });

      if (response.response_code === VoteAPIResponseCode.VALID) {
        return response;
      }

      throw new Error(response.response_code);
    },
  });

  return {
    trackDownload: mutateAsync,
    ...rest,
  };
};
