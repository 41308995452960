import { AspectRatio, Box, Flex, Heading, Link, Spacer, Text, Image } from '@telescope/cassini-ui';
import parse from 'html-react-parser';
import { DataContext, UseWidgetResponse, useWidget } from 'providers';
import { useContext } from 'react';
import { GoChevronLeft, GoChevronRight } from 'react-icons/go';
import { Link as RouterLink } from 'react-router-dom';
import { slugify } from 'utils';
import { useVoteHistory } from '../hooks';
import { useVotingGrid } from '../providers';

// TODO: fix voteOption type
export const NomineeCard = ({ voteOption }: { voteOption: any }) => {
  const { language } = useContext(DataContext);
  const { data: nomineeCard } = useWidget({
    select: (data: UseWidgetResponse) => data.snapshot.text.nominee_card,
  });

  const { activeCategory, activeVoteOption } = useVotingGrid();
  const { data: voteHistory } = useVoteHistory();
  const categoryVotedOption = voteHistory.history[activeCategory.id];
  const voted = categoryVotedOption === voteOption.id;
  const isActive = activeVoteOption && activeVoteOption.id === voteOption.id;

  return (
    <Link
      as={RouterLink}
      to={slugify(voteOption.name)}
      aria-label={`vote for ${voteOption[`name_${language}`]}`}
      _hover={{ textDecoration: 'none' }}
      display={'block'}
      className="NomineeCard__root"
      data-vote-option-name={voteOption.name}
      data-group
    >
      <Flex
        bg={isActive ? 'NomineeCard.root.bg--active' : 'NomineeCard.root.bg'}
        _groupHover={{ bg: 'NomineeCard.root.bg--hover' }}
        p={1}
        pr={2}
        gridGap={3}
        rounded={'md'}
      >
        <AspectRatio w="100%" maxW="65px" flexShrink={0} ratio={65 / 98} rounded={'md'} bg="NomineeCard.image.bg">
          <Image rounded={'md'} alt="" src={voteOption.thumbnail} />
        </AspectRatio>

        <Flex direction="column" w="100%" minW={0}>
          <Box>
            <Heading size="small" textAlign="initial" mb={1} fontWeight={600}>
              {parse(voteOption[`name_${language}`])}
            </Heading>

            {voteOption[`studio_name_${language}`] && (
              <Text
                size="small"
                overflow="hidden"
                whiteSpace="nowrap"
                textOverflow="ellipsis"
                color="NomineeCard.studio.text"
                fontWeight={500}
              >
                {parse(voteOption[`studio_name_${language}`])}
              </Text>
            )}
          </Box>

          <Spacer />

          <Flex
            align={'center'}
            gap={1}
            color="Button.primary.bg"
            alignSelf="flex-end"
            fontSize={'2xl'}
            _groupHover={{ color: 'inherit' }}
          >
            {voted && (
              <Text fontSize="xs" fontWeight={700}>
                {nomineeCard.voted_button_text[language]}
              </Text>
            )}
            {language === 'ar' ? <GoChevronLeft /> : <GoChevronRight />}
          </Flex>
        </Flex>
      </Flex>
    </Link>
  );
};
