import { WID } from 'utils/constants';

export const getWidgetId = () => {
  const search = new URLSearchParams(window.location.search);
  const widgetId = search.get('wid');

  return widgetId || WID;
};

export const getSidString = (key = 'sid') => {
  const search = new URLSearchParams(window.location.search);
  const sid = search.get(key);
  const sidString = sid ? `/snapshot/${sid}` : '';

  return sidString;
};
