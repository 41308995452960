import {
  Box,
  Container,
  Divider,
  Flex,
  Grid,
  GridItem,
  IconButton,
  Link,
  Text,
  Image,
  VStack,
} from '@telescope/cassini-ui';
import { DataContext, useWidget, UseWidgetResponse } from 'providers';
import React, { useContext, useEffect } from 'react';
import { GenericObject } from 'types';
import { LanguagesMenu } from './LanguagesMenu';
import { iconMap } from './utils';
import { getTextDirection } from 'utils';

export const Footer = () => {
  const { data } = useWidget({ select: (data: UseWidgetResponse) => data.snapshot.text.footer });
  const { columns = {}, copyright } = data;
  const { language, languageCode } = useContext(DataContext);

  useEffect(() => {
    window.evidon?.notice?.activateTranslations(languageCode.toLowerCase());
  }, [languageCode]);

  useEffect(() => {
    document.documentElement.setAttribute('lang', language);

    const direction = getTextDirection(language);
    document.documentElement.setAttribute('dir', direction);
  }, [language]);

  const getLink = (link: GenericObject) => {
    switch (link.type) {
      case 'cookie_consent_tool':
        return (
          <Link
            as="button"
            fontSize={14}
            onClick={() => window.evidon.notice.showPreferencesDialog()}
            variant={'link'}
            _hover={{ color: 'Footer.text--hover', textDecoration: 'underline' }}
          >
            {link.copy[language]}
          </Link>
        );
      case 'icon_link':
        return (
          <>
            <IconButton
              aria-label={link.copy[language]}
              variant="link"
              display="contents"
              color="#FFFFFF"
              sx={{ svg: { display: 'initial', marginRight: '5px' } }}
              icon={React.createElement(iconMap[link.icon])}
            />
            <Link
              href={link.link[language]}
              target="_blank"
              fontSize={14}
              _hover={{ color: 'Footer.text--hover', textDecoration: 'underline' }}
            >
              {link.copy[language]}
            </Link>
          </>
        );
      case 'link':
      default:
        if (!link.copy[language]) return null;
        return (
          <Link
            href={link.link}
            target="_blank"
            fontSize={14}
            _hover={{ color: 'Footer.text--hover', textDecoration: 'underline' }}
          >
            {link.copy[language]}

            {link.icon && (
              <Image src={link.icon} maxH="30px" maxW="30px" display="inline-block" mx="6px" verticalAlign="middle" />
            )}
          </Link>
        );
    }
  };

  const getColumns = () => {
    return Object.keys(columns).map((key: string, index: number) => {
      return (
        <GridItem key={key} mb={{ base: 5, md: 0 }} w="full">
          <Grid gridTemplateColumns={{ base: 'repeat(2, 1fr)', md: '1fr' }}>
            <Box>
              <Text key={index} fontWeight={600} fontSize={[14, 16]} color="Footer.text--strong" mb={{ md: 3 }}>
                {columns[key].title[language]}
              </Text>
            </Box>
            <VStack align="flex-start">
              {columns[key].links.map((link: GenericObject, index: number) => {
                return <Box key={index}>{getLink(link)}</Box>;
              })}
            </VStack>
          </Grid>
        </GridItem>
      );
    });
  };

  return (
    <Box as="footer" bg="Footer.bg" color="Footer.text" fontFamily="Lato">
      <Container py={8}>
        <Grid
          templateColumns={{ base: '1fr', md: 'repeat(3, 1fr)' }}
          gridGap={{ base: 0, md: 12 }}
          justifyItems={{ base: 'flex-start', md: 'center' }}
        >
          {getColumns()}
        </Grid>
        <Divider variant="divider" borderBottom="1px" width="initial" mx={0} />
        <Flex
          className="Footer__copyright-section"
          direction={{ base: 'column', sm: 'row' }}
          justifyContent="space-between"
          align="center"
        >
          <Flex
            className="Footer__legal-wrapper"
            align="center"
            direction={{ base: 'column', sm: 'row' }}
            mb={{ base: 5, sm: 0 }}
          >
            <Image
              className="Footer__logo"
              src="https://ts-cms-production.votenow.tv/campaign/10/12/1012927/17029484496580ee61a620e3.53781935.png"
              maxW={'158px'}
              marginInlineEnd={2}
              mb={{ base: 3, sm: 0 }}
            />
            <Text
              display="flex"
              alignItems={'center'}
              fontSize="sm"
              _before={{
                base: { display: 'none' },
                sm: {
                  bg: '#4a4e58',
                  content: '""',
                  display: 'block',
                  flex: '0 0 auto',
                  height: '1.125rem',
                  marginInlineEnd: 2,
                  width: '0.0625rem',
                },
              }}
            >
              {copyright[language]}
            </Text>
          </Flex>
          <Flex>
            <LanguagesMenu />
          </Flex>
        </Flex>
      </Container>
    </Box>
  );
};
