import { Box, Flex, Hide, IconButton, Link, Menu, MenuButton, MenuItem, MenuList, Show } from '@telescope/cassini-ui';
import { HamburgerIcon, LogoIconLarge, LogoIconSmall } from 'components';
import { DataContext, UseWidgetResponse, useWidget } from 'providers';
import { useContext } from 'react';

export const HeaderMenu = () => {
  const { language } = useContext(DataContext);
  const { data } = useWidget({ select: (data: UseWidgetResponse) => data.snapshot.text.header });

  return (
    <Flex h="60px" bg="#23252B" fontFamily="Lato, sans-serif" pr={{ base: 0, md: '10px' }} gap="18px">
      <Menu>
        <MenuButton
          as={IconButton}
          w="60px"
          h="60px"
          minW="auto"
          bg="transparent"
          _active={{ bg: '#141519', fill: 'secondary.600' }}
          _hover={{ bg: '#141519' }}
          textAlign="left"
          fill="#fff"
          icon={<HamburgerIcon fill="inherit" />}
          rounded={0}
        ></MenuButton>

        <MenuList bg="#141519" w="100vw" border="none" borderRadius={0} m={0} mt={-2}>
          {data.links.map((link: Record<string, any>) => {
            return (
              <MenuItem bg="transparent" key={link.url} as={Box} p={0}>
                <Link
                  isExternal
                  href={link.url}
                  color="secondary.700"
                  _hover={{ color: '#fff', bg: '#23252B', textDecoration: 'none' }}
                  _focusVisible={{
                    color: '#fff',
                    bg: '#23252B',
                    boxShadow: 'none',
                    outline: '.25rem solid #a0a0a0',
                    outlineOffset: '-0.25rem',
                  }}
                  fontSize="16px"
                  fontWeight={500}
                  p="12px"
                  w="100%"
                >
                  {link.copy[language]}
                </Link>
              </MenuItem>
            );
          })}
        </MenuList>
      </Menu>

      <Link
        isExternal
        href={data.logo.link}
        data-group
        alignSelf="center"
        fill="primaryBase"
        _hover={{ '@media(hover: hover)': { fill: '#fff' } }}
      >
        <Show above="md">
          <LogoIconLarge fill="inherit" />
        </Show>

        <Hide above="md">
          <LogoIconSmall fill="inherit" />
        </Hide>
      </Link>
    </Flex>
  );
};
