import { useContext } from 'react';
import { ModalsContext } from './modals-context';

export function useModal() {
  const ctx = useContext(ModalsContext);

  if (!ctx) {
    throw new Error(
      '[@telescope/cassini-modals] useModal hook was called outside of context, wrap your app with ModalsProvider component',
    );
  }

  return ctx;
}
