import { ComponentStyleConfig } from '@chakra-ui/react';

export const modalTheme: ComponentStyleConfig = {
  baseStyle: {
    dialog: {
      m: {
        base: 0,
        md: 'auto',
      },
      bg: 'Modal.bg',
      color: 'Modal.text',
      'span a': {
        color: 'Modal.link.text',
      },
      py: 16,
      px: { base: 5 },
    },
    header: {
      px: { base: 0, lg: 2 },
    },
    body: {
      px: { base: 0, lg: 2 },
    },
    footer: {
      px: { base: 0, lg: 2 },
    },
    closeButton: {
      fontSize: 'md',
    },
  },
  sizes: {
    sm: {
      dialog: {
        maxWidth: { md: '688px' },
        rounded: 'xl',
      },
    },
    md: {
      dialog: {
        maxWidth: { md: '688px', lg: '1050px' },
        rounded: 'xl',
        px: 16,
      },
    },
  },
};
