import { radioAnatomy as parts } from '@chakra-ui/anatomy';
import { ComponentStyleConfig } from '@chakra-ui/react';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/styled-system';
import { checkboxTheme } from './Checkbox';
const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

const baseStyleControl = defineStyle(props => {
  const controlStyle = checkboxTheme.baseStyle?.(props).control;

  return {
    bg: 'secondaryBlueDark',
    _checked: {
      ...controlStyle?.['_checked'],
      color: 'secondaryScooterBase',
      _before: {
        content: `""`,
        display: 'inline-block',
        pos: 'relative',
        w: '50%',
        h: '50%',
        borderRadius: '50%',
        bg: 'currentColor',
      },
    },
  };
});

const baseStyleLabel = defineStyle({
  fontSize: 'sm',
  color: 'text.subtle',
  _checked: {
    color: 'white',
  },
});

const baseStyle = definePartsStyle(props => ({
  control: baseStyleControl(props),
  label: baseStyleLabel,
}));

const sizes = {
  lg: {
    label: {
      fontSize: 'sm',
    },
  },
};

export const radioTheme: ComponentStyleConfig = {
  baseStyle,
  sizes,
  defaultProps: {
    size: 'lg',
  },
};
