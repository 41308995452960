export type AuthUser = {
  user: Record<string, string>;
  isRegistered: boolean;
  votestring: {
    total: number;
    [x: string]: string | number;
  };
  role: UserRole;
};

export type UserResponse = {
  response_code: string;
  registered: boolean;
  votestring?: string;
};

export const UserRole = {
  JUDGE: 'judge',
  AUDIENCE: 'audience',
} as const;

// eslint-disable-next-line -- intentionally naming the object the same as the type
export type UserRole = typeof UserRole[keyof typeof UserRole];
