import { Link } from '@telescope/cassini-ui';
import { useContext } from 'react';
import { DataContext } from 'providers';
import { GenericObject } from 'types';
import { NavLink as NavLinkRouter, useLocation } from 'react-router-dom';

export const NavLink = ({ data }: { data: GenericObject }) => {
  const { language } = useContext(DataContext);
  const isInternalRoute = data.link.startsWith('/');
  const location = useLocation();
  const isActive = location.pathname.startsWith(data.link);

  if (!isInternalRoute) {
    return (
      <Link
        href={data.link}
        target="_blank"
        aria-label={data.aria_label}
        fontFamily="Lato, sans-serif"
        fontWeight="700"
        fontSize={'14px'}
        color="NavLink.text"
        _hover={{ color: 'NavLink.text--hover', textDecoration: 'none' }}
      >
        {data.text[language]}
      </Link>
    );
  }

  return (
    <Link
      as={NavLinkRouter}
      to={data.link}
      key={data.name}
      aria-label={data.aria_label}
      fontFamily="Lato, sans-serif"
      fontWeight="700"
      fontSize={'14px'}
      color={isActive ? 'NavLink.text--active' : 'NavLink.text'}
      _activeLink={{ color: 'NavLink.text--active' }}
      _hover={{ color: 'NavLink.text--hover', textDecoration: 'none' }}
      isExternal
    >
      {data.text[language]}
    </Link>
  );
};
