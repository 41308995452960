import React, { useReducer } from 'react';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@telescope/cassini-ui';
import { randomId } from 'utils';
import {
  ModalsContext,
  ModalSettings,
  ConfirmLabels,
  OpenConfirmModal,
  OpenContextModal,
  ContextModalProps,
  ModalsContextProps,
} from './modals-context';
import { ConfirmModal } from './ConfirmModal';
import { modalsReducer } from './modals-reducer';

export interface ModalsProviderProps {
  /** Your app */
  children: React.ReactNode;

  /** Predefined modals */
  modals?: Record<string, React.FC<ContextModalProps<any>>>;

  /** Shared Modal component props, applied for every modal */
  modalProps?: ModalSettings;

  /** Confirm modal labels */
  labels?: ConfirmLabels;
  header?: React.ReactNode;
  footer?: React.ReactNode;
}

function separateConfirmModalProps(props: OpenConfirmModal) {
  if (!props) {
    return { confirmProps: {}, modalProps: {} };
  }

  const {
    id,
    header,
    body,
    footer,
    onCancel,
    onConfirm,
    closeOnConfirm,
    closeOnCancel,
    cancelProps,
    confirmProps,
    groupProps,
    labels,
    ...others
  } = props;

  return {
    confirmProps: {
      id,
      header,
      body,
      footer,
      onCancel,
      onConfirm,
      closeOnConfirm,
      closeOnCancel,
      cancelProps,
      confirmProps,
      groupProps,
      labels,
    },
    modalProps: {
      id,
      ...others,
    },
  };
}

export function ModalsProvider({ children, modalProps, labels, modals = {} }: ModalsProviderProps) {
  const [state, dispatch] = useReducer(modalsReducer, { modals: [], current: null });
  const closeAll = (canceled?: boolean) => {
    state.modals.forEach(modal => {
      if (modal.type === 'confirm' && canceled) {
        modal.props?.onCancel?.();
      }

      modal.props?.onClose?.();
    });
    dispatch({ type: 'CLOSE_ALL' });
  };

  const openModal = ({ modalId, ...props }: ModalSettings) => {
    const id = modalId || randomId();

    dispatch({
      type: 'OPEN',
      payload: {
        id,
        type: 'content',
        props,
      },
    });
    return id;
  };

  const openConfirmModal = ({ modalId, ...props }: OpenConfirmModal) => {
    const id = modalId || randomId();
    dispatch({
      type: 'OPEN',
      payload: {
        id,
        type: 'confirm',
        props,
      },
    });
    return id;
  };

  const openContextModal = (modal: string, { modalId, ...props }: OpenContextModal) => {
    const id = modalId || randomId();
    dispatch({
      type: 'OPEN',
      payload: {
        id,
        type: 'context',
        props,
        ctx: modal,
      },
    });
    return id;
  };

  const closeModal = (id: string, canceled?: boolean) => {
    if (state.modals.length <= 1) {
      closeAll(canceled);
      return;
    }

    const modal = state.modals.find(item => item.id === id);

    if (!modal) return;
    if (modal?.type === 'confirm' && canceled) {
      modal.props?.onCancel?.();
    }
    modal?.props?.onClose?.();
    dispatch({ type: 'CLOSE', payload: modal.id });
  };

  const ctx: ModalsContextProps = {
    modals: state.modals,
    openModal,
    openConfirmModal,
    openContextModal,
    closeModal,
    closeAll,
  };

  const getCurrentModal = () => {
    switch (state.current?.type) {
      case 'context': {
        const { innerProps, ...rest } = state.current.props;
        const ContextModal = modals[state.current.ctx];

        return {
          modalProps: rest,
          content: <ContextModal innerProps={innerProps} context={ctx} id={state.current.id} />,
        };
      }
      case 'confirm': {
        const { modalProps: separatedModalProps, confirmProps: separatedConfirmProps } = separateConfirmModalProps(
          state.current.props,
        );

        return {
          modalProps: separatedModalProps,
          content: (
            <ConfirmModal
              {...separatedConfirmProps}
              id={state.current.id}
              labels={state.current.props.labels || labels}
            />
          ),
        };
      }
      case 'content': {
        const { body, header, footer, ...rest } = state.current.props;
        const content = (
          <>
            {header && <ModalHeader>{header}</ModalHeader>}
            <ModalBody py={4}>{body}</ModalBody>
            {footer && <ModalFooter>{footer}</ModalFooter>}
          </>
        );

        return {
          modalProps: rest,
          content,
        };
      }
      default: {
        return {
          modalProps: {},
          content: null,
        };
      }
    }
  };

  const { modalProps: currentModalProps, content } = getCurrentModal();

  return (
    <ModalsContext.Provider value={ctx}>
      <Modal
        {...modalProps}
        {...currentModalProps}
        isOpen={state.modals.length > 0}
        onClose={() => closeModal(state?.current?.id ?? '')}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton size="lg" />
          {content}
        </ModalContent>
      </Modal>

      {children}
    </ModalsContext.Provider>
  );
}
