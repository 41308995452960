import { Box, Container, Flex, useBreakpointValue } from '@telescope/cassini-ui';
import { AuthNav, Footer, Header, Navigation } from 'features';

export const AppShell = (props: { children: React.ReactNode }) => {
  const backgroundStars = useBreakpointValue(
    {
      base: undefined,
      lg: {
        content: '""',
        bg: 'url(https://ts-cms-production.votenow.tv/campaign/10/12/1012927/1699399723654ac82b99eb86.54065196.png) no-repeat',
        w: '278px',
        h: '149px',
        position: 'absolute',
        top: '0',
        right: '0',
        pointerEvents: 'none',
        zIndex: -1,
      },
    },
    { ssr: false },
  );

  return (
    <Flex direction="column" h="full">
      <Header />
      <Navigation />
      <Box
        bg="url(https://ts-cms-production.votenow.tv/campaign/10/12/1012927/1699401826654ad062abe268.86963647.jpg)"
        bgRepeat="no-repeat"
        bgSize="cover"
        backgroundPosition={'center center'}
        zIndex={0}
        flex={1}
      >
        <Container as="main" position="relative" _before={backgroundStars}>
          <AuthNav />
          {props.children}
        </Container>
      </Box>
      <Footer />
    </Flex>
  );
};
