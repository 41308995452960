import { Container, Flex } from '@telescope/cassini-ui';
import { Footer, VotingHeader } from 'features';

export const VotingShell = (props: { children: React.ReactNode }) => {
  return (
    <Flex direction="column" h="full">
      <VotingHeader />
      <Container as="main" flex={1} py={12}>
        {props.children}
      </Container>
      <Footer />
    </Flex>
  );
};
