import { Box, Button, ButtonProps, Container, Image, chakra, useBreakpointValue } from '@telescope/cassini-ui';
import { ChevronLeft, ShareIcon } from 'components';
import { useAuth } from 'features';
import useShareStore from 'features/share/share-store';
import { CategoriesMenu, CategoryNavigation, useVotingGrid } from 'features/voting-app';
import { DataContext, UseWidgetResponse, useWidget } from 'providers';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'utils';

const NavButton = chakra(Button, {
  baseStyle: {
    color: 'white',
    bg: 'woodsmoke',
    _hover: {
      bg: 'shark',
    },
    _active: {
      bg: 'shark',
    },
  },
});

const ShareButton = (props: ButtonProps) => <NavButton size="sm" leftIcon={<ShareIcon />} {...props} />;
const CategoriesButton = (props: ButtonProps) => <NavButton size={{ base: 'xs', sm: 'sm' }} {...props} />;

// TODO: Implement Share functionality
export const VotingHeader = () => {
  const navigate = useNavigate();
  const logo = useBreakpointValue({
    base: 'https://ts-cms-production.votenow.tv/campaign/10/12/1012927/1698358283653ae40bb30f40.37240859.png',
    md: 'https://ts-cms-production.votenow.tv/campaign/10/12/1012927/1698353626653ad1dacb9313.20760642.png',
  });
  const { toggleShare } = useShareStore();
  const { language } = useContext(DataContext);
  const { data } = useWidget({ select: (data: UseWidgetResponse) => data.snapshot.text.categories_navigation });
  const { data: shareButtonText } = useWidget({
    select: (data: UseWidgetResponse) => data.snapshot.text.share_modal.cta,
  });
  const { role } = useAuth();
  const isAudienceRole = role === 'audience';
  const { votedCategories } = useVotingGrid();
  const shouldShowShareButton = votedCategories.length > 0 && isAudienceRole;

  return (
    <>
      <Box as="header" w="100%" bg="black" position={'relative'}>
        <Container as="nav" px={{ base: 2, sm: 5 }} py={3} display="grid" gridTemplateColumns={'1fr 1fr 1fr'}>
          <Box justifySelf="start">
            <NavButton size={{ base: 'xs', sm: 'sm' }} leftIcon={<ChevronLeft />} onClick={() => navigate(ROUTES.HOME)}>
              {data.home[language]}
            </NavButton>
          </Box>
          <Box justifySelf="center">
            <Image maxW={180} src={logo} alt="logo" />
          </Box>
          <Box justifySelf="end">
            {shouldShowShareButton && (
              <ShareButton hideBelow={'lg'} onClick={toggleShare}>
                {shareButtonText[language]}
              </ShareButton>
            )}
            <CategoriesMenu
              Trigger={<CategoriesButton hideFrom="lg">{data.all_categories_label[language]}</CategoriesButton>}
            />
          </Box>
        </Container>
      </Box>
      <CategoryNavigation />
    </>
  );
};
