import { ComponentStyleConfig } from '@chakra-ui/react';

export const headingTheme: ComponentStyleConfig = {
  baseStyle: {
    textAlign: 'center',
    fontWeight: '500',
  },
  sizes: {
    extraSmall: { fontSize: 'lg' },
    small: { fontSize: ['sm', null, 'lg'] },
    medium: { fontSize: '24px' },
    large: { fontSize: '3xl' },
    extraLarge: { fontSize: '4xl' },
  },
};
