import { Box, Flex, Heading, Image, Text } from '@telescope/cassini-ui';
import { DataContext, UseWidgetResponse, useWidget } from 'providers';
import React, { useContext } from 'react';
import parse from 'html-react-parser';

export const PreVoteClosedPage = () => {
  const { language } = useContext(DataContext);
  const { data: copy } = useWidget({ select: (data: UseWidgetResponse) => data.snapshot.text.pre_vote_closed });

  return (
    <Box as="section" py={10} minH={'10vh'}>
      <Flex direction="column" alignItems={{ base: 'center', md: 'flex-start' }} mb={12} textAlign={'center'}>
        <Image
          maxW={{ base: '180px', md: '242px' }}
          src="https://ts-cms-production.votenow.tv/campaign/10/12/1012927/1698353626653ad1dacb9313.20760642.png"
          alt="Anime Awards Crunchyroll Logo"
          mb={6}
        />
        {copy.headline[language] && (
          <Heading fontFamily="serifHeading" textAlign="left" fontSize={{ base: '28px', md: '54px' }} mb={3}>
            {parse(copy.headline[language])}
          </Heading>
        )}

        {copy.description[language] && (
          <Text textAlign={['center', 'left']} mb={{ base: 6, md: 12 }}>
            {parse(copy.description[language])}
          </Text>
        )}
      </Flex>
    </Box>
  );
};
