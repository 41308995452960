import { ComponentStyleConfig } from '@chakra-ui/react';
import { theme } from '@chakra-ui/theme';

export const buttonTheme: ComponentStyleConfig = {
  baseStyle: {
    borderRadius: 'full',
    overflowWrap: 'break-word',
    whiteSpace: 'normal',

    lineHeight: 'normal',
    minH: '40px',
    _disabled: {
      pointerEvents: 'none',
    },
  },
  sizes: {
    sm: {
      px: 4,
      py: 2,
    },
    md: {
      px: 12,
      fontSize: 'sm',
    },
  },
  variants: {
    primary: props => ({
      ...theme.components.Button.variants!.solid(props),
      borderColor: 'Button.primary.border',
      color: 'Button.primary.text',
      backgroundColor: 'Button.primary.bg',
      border: 0,
      _hover: {
        backgroundColor: 'Button.primary.bg--hover',
        borderColor: 'Button.primary.border--hover',
        color: 'Button.primary.text--hover',
      },
      _active: {
        backgroundColor: 'Button.primary.bg--hover',
        borderColor: 'Button.primary.border--hover',
        color: 'Button.primary.text--hover',
      },
      _disabled: {
        border: '2px',
        opacity: 1,
        backgroundColor: 'Button.primary.bg--disabled',
        borderColor: 'Button.primary.border--disabled',
        color: 'Button.primary.text--disabled',
        _hover: {
          backgroundColor: 'Button.primary.bg--disabled',
          borderColor: 'Button.primary.border--disabled',
          color: 'Button.primary.text--disabled',
        },
      },
    }),
    primaryOutline: props => ({
      ...theme.components.Button.variants!.outline(props),
      borderColor: 'Button.primary.border',
      color: 'Button.primary.text',
      _hover: {
        backgroundColor: 'transparent',
        borderColor: 'Button.primary.bg--hover',
        color: 'Button.primary.bg--hover',
      },
      _active: {
        backgroundColor: 'Button.primary.bg--hover',
        borderColor: 'Button.primary.border--hover',
        color: 'Button.primary.text--hover',
      },
    }),
    secondary: props => ({
      ...theme.components.Button.variants!.outline(props),
      borderColor: 'Button.secondary.border',
      color: 'Button.secondary.text',
      backgroundColor: 'Button.secondary.bg',
      border: 'none',
      _hover: {
        backgroundColor: 'Button.secondary.bg--hover',
        borderColor: 'Button.secondary.border--hover',
        color: 'Button.secondary.text--hover',
      },
      _active: {
        backgroundColor: 'Button.secondary.bg--hover',
        borderColor: 'Button.secondary.border--hover',
        color: 'Button.secondary.text--hover',
      },
    }),
    tertiary: {
      color: '#FFFFFF',
      backgroundColor: 'transparent',
      border: '2px solid #FFFFFF',
    },
    link: {
      color: 'primaryBase',
      backgroundColor: 'transparent',
      _hover: {
        textDecoration: 'none',
      },
    },
  },
};
