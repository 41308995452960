import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { UseWidgetResponse, useWidget } from 'providers';

export default function ScrollToTop() {
  const { pathname } = useLocation();
  const { data: snapshotType } = useWidget({
    select: (data: UseWidgetResponse) => data.snapshot.text.snapshot_settings.snapshot_type,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname, snapshotType]);

  return null;
}
