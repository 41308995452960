import { Box, Grid, GridItem, Image, SystemStyleObject, Text } from '@telescope/cassini-ui';
import { VotedCategory } from 'features';
import { merge } from 'merge-anything';
import { DataContext } from 'providers';
import { forwardRef, useContext } from 'react';
import parse from 'html-react-parser';

const ASSETS_URL = 'https://content.votenow.tv/app/projects/crunchyroll/anime-awards-2024/images/ugc/templates/00';
const getTemplateIndex = (numCategories: number) => {
  const templateThresholds = [1, 8, 16, 25];
  return templateThresholds.find(threshold => numCategories <= threshold) || 25;
};
const MAX_CATEGORIES = 25;

type Orientation = 'horizontal' | 'vertical';
interface SocialGraphicTemplateStyles {
  root?: SystemStyleObject;
  gridWrapper?: SystemStyleObject;
  grid?: SystemStyleObject;
  gridItem?: SystemStyleObject;
  categoryWrapper?: SystemStyleObject;
  categoryImage?: SystemStyleObject;
  categoryTitle?: SystemStyleObject;
}

const templateStyles = (orientation: Orientation, templateThreshold: number): SocialGraphicTemplateStyles => {
  const baseStyles: SocialGraphicTemplateStyles = {
    root: {
      w: orientation === 'horizontal' ? 1600 : 900,
      h: orientation === 'horizontal' ? 900 : 1600,
      bg: 'black',
      bgPosition: 'center',
      bgRepeat: 'no-repeat',
      position: 'fixed',
      top: -1000000,
      left: -1000000,
      zIndex: 100,
      direction: 'ltr',
    },
    gridWrapper: {
      position: 'absolute',
    },
    grid: {
      gridTemplateColumns: '1fr',
    },
    gridItem: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      position: 'relative',
      bgGradient: 'linear(to-r, #345AA8, #354484)',
    },
    categoryWrapper: {
      '> svg': {
        position: 'absolute',
        top: 0,
        left: 0,
        height: 'auto',
      },
    },
    categoryImage: {
      w: '100%',
      border: '1px solid #F9D8A7',
      position: 'relative',
      zIndex: 1,
    },
    categoryTitle: {
      textAlign: 'center',
      fontWeight: 'bold',
      wordBreak: 'break-word',
      whiteSpace: 'normal',
      color: '#F9D8A7',
      letterSpacing: '0.5px',
      px: '0px',
      position: 'relative',
    },
  };

  const variantStyles: Record<Orientation, Record<number, SocialGraphicTemplateStyles>> = {
    horizontal: {
      1: {
        gridWrapper: {
          top: '50px',
          left: 'auto',
          right: '229px',
        },
        grid: {
          gridTemplateColumns: '1fr',
        },
        gridItem: { px: '28px', py: '30px' },
        categoryWrapper: {
          w: '388px',
          '> svg': {
            width: '53px',
          },
        },
        categoryTitle: {
          fontSize: '38px',
          mt: '20px',
          top: '-16px',
        },
      },
      8: {
        gridWrapper: {
          top: '50px',
          left: '70px',
        },
        grid: {
          gridTemplateColumns: 'repeat(4, 1fr)',
          columnGap: '57px',
          rowGap: '50px',
        },
        gridItem: { px: '15px', py: '15px' },
        categoryWrapper: {
          w: '184px',
          '> svg': {
            width: '27px',
          },
        },
        categoryTitle: {
          fontSize: '18px',
          mt: '10px',
          top: '-7px',
        },
      },
      16: {
        gridWrapper: {
          top: '197px',
          left: '52px',
        },
        grid: {
          gridTemplateColumns: 'repeat(8, 1fr)',
          columnGap: '29px',
          rowGap: '25px',
        },
        gridItem: { px: '10px', py: '12px' },
        categoryWrapper: {
          w: '142px',
          '> svg': {
            width: '21px',
          },
        },
        categoryTitle: {
          fontSize: '12px',
          mt: '10px',
          top: '-6px',
        },
      },
      25: {
        gridWrapper: {
          top: '48px',
          left: '42px',
        },
        grid: {
          gridTemplateColumns: 'repeat(9, 1fr)',
          columnGap: '32px',
          rowGap: '35px',
        },
        gridItem: { px: '10px', py: '10px' },
        categoryWrapper: {
          w: '120px',
          '> svg': {
            width: '18px',
          },
        },
        categoryTitle: {
          fontSize: '11px',
          mt: '10px',
          lineHeight: '11px',
          top: '-5px',
        },
      },
    },
    vertical: {
      1: {
        gridWrapper: {
          top: '365px',
          left: '201px',
          right: 'auto',
        },
        grid: {
          gridTemplateColumns: '1fr',
        },
        gridItem: { px: '28px', py: '30px' },
        categoryWrapper: {
          w: '444px',
          '> svg': {
            backgroundSize: '55px',
          },
        },
        categoryTitle: {
          fontSize: '38px',
          mt: '30px',
          top: '-16px',
        },
      },
      8: {
        gridWrapper: {
          top: '534px',
          left: '46px',
        },
        grid: {
          gridTemplateColumns: 'repeat(4, 1fr)',
          columnGap: '37px',
          rowGap: '30px',
        },
        gridItem: { px: '10px', py: '10px' },
        categoryWrapper: {
          w: '154px',
          '> svg': {
            width: '19px',
          },
        },
        categoryTitle: {
          fontSize: '15px',
          my: '10px',
          top: '-5px',
        },
      },
      16: {
        gridWrapper: {
          top: '319px',
          left: '106px',
        },
        grid: {
          gridTemplateColumns: 'repeat(4, 1fr)',
          columnGap: '45px',
          rowGap: '27px',
        },
        gridItem: { px: '8px', py: '8px' },
        categoryWrapper: {
          w: '120px',
          '> svg': {
            width: '15px',
          },
        },
        categoryTitle: {
          fontSize: '12px',
          mt: '10px',
          top: '-6px',
        },
      },
      25: {
        gridWrapper: {
          top: '281px',
          left: '71px',
        },
        grid: {
          gridTemplateColumns: 'repeat(5, 1fr)',
          columnGap: '45px',
          rowGap: '22px',
        },
        gridItem: { px: '8px', py: '8px' },
        categoryWrapper: {
          w: '100px',
          '> svg': {
            width: '15px',
          },
        },
        categoryTitle: {
          fontSize: '11px',
          mt: '7px',
          top: '-5px',
        },
      },
    },
  };

  const overrides = templateThreshold ? variantStyles[orientation][templateThreshold] : {};

  return merge(baseStyles, overrides);
};

interface SocialGraphicProps {
  orientation?: Orientation | null;
  votedCategories: VotedCategory[];
}
export const SocialGraphic = forwardRef<HTMLDivElement, SocialGraphicProps>((props, ref) => {
  const { orientation, votedCategories } = props;
  const { language } = useContext(DataContext);
  const data = votedCategories
    .map(category => {
      return {
        copy: parse(category![`name_${language}`]),
        image: category.voteOption.ugc_image,
      };
    })
    .slice(0, MAX_CATEGORIES);

  if (!orientation) {
    return null;
  }

  const templateThreshold = getTemplateIndex(data.length);
  const styles = templateStyles(orientation, templateThreshold);

  return (
    <>
      <Box ref={ref} sx={styles.root}>
        <Image
          crossOrigin="anonymous"
          src={`${ASSETS_URL}/${orientation}_${templateThreshold}.png?cachebuster=${new Date().getTime()}`}
        />
        <Box sx={styles.gridWrapper}>
          <Grid sx={styles.grid}>
            {data.map((item, index) => (
              <GridItem key={index} sx={styles.gridItem}>
                <Box sx={styles.categoryWrapper}>
                  <svg width="53" height="57" viewBox="0 0 53 57" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M26.4399 0C25.4499 23.01 22.7199 27.24 0.439941 28.42C22.6199 29.37 25.3499 33.52 26.4799 56.51C27.3499 33.51 30.2599 29.42 52.5599 28.47C30.1399 27.29 27.4299 23.01 26.4399 0Z"
                      fill="#F9D8A7"
                    />
                  </svg>
                  <Image
                    crossOrigin="anonymous"
                    src={`${item.image}?cachebuster=${new Date().getTime()}`}
                    sx={styles.categoryImage}
                  />
                  <Text sx={styles.categoryTitle}>{item.copy}</Text>
                </Box>
              </GridItem>
            ))}
          </Grid>
        </Box>
      </Box>
    </>
  );
});
