import _, { keyBy } from 'lodash-es';
import { DataContext, UseWidgetResponse, useWidget } from 'providers';
import React, { createContext, useContext, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ICategory, IVoteOption } from 'types';
import { CAT_WID, CONTESTANTS_WID, WIDGET_STATE_OPTIONS, slugify, getSidString } from 'utils';
import { CategoryId, useVoteHistory } from '../hooks';

export type VotedCategory = Omit<ICategory, 'voteOptions'> & { voteOption: IVoteOption };
export interface VotingGridContextProps {
  /**
   * Object of all categories, keyed by category id.
   */
  categoriesBySlug: Record<CategoryId, ICategory>;
  /**
   * Array of all categories, sorted by name, with voteOptions sorted by name.
   */
  allCategories: ICategory[];
  /**
   * Active category, based on the categorySlug from the URL.
   */
  activeCategory: ICategory;
  /**
   * Array of all categories that are allowed for the current language.
   */
  allowedCategories: ICategory[];
  /**
   * Next category, based on the active category.
   * If the active category is the last one, returns the first category.
   */
  nextCategory: ICategory;
  /**
   * Previous category, based on the active category.
   * If the active category is the first one, returns the last category.
   */
  previousCategory: ICategory;
  /**
   * Active vote option, based on the voteOptionSlug from the URL.
   */
  activeVoteOption: IVoteOption;
  /**
   * Returns true if the category is allowed for the current language.
   */
  isCategoryAllowed: (category: ICategory) => boolean;
  /**
   * Array of categories that have been voted on in the current voting window.
   */
  votedCategories: VotedCategory[];
  /**
   * Array of categories that have been voted on in the previous voting window, and that have not been resubmitted yet.
   * Once the user resubmits the votes, this array will be empty.
   */
  previouslyVotedCategories: VotedCategory[];
  /**
   * First category that is allowed and has not been voted on yet.
   * If all categories have been voted on, returns undefined.
   */
  firstVoteableCategory: ICategory | undefined;
  /**
   * Returns true if all allowed categories have been voted on.
   */
  areAllCategoriesVoted: boolean;
  /**
   * Returns true if the user has voted in the previous voting window and has not resubmitted the votes yet.
   */
  hasPreviousBallot: boolean;
  /**
   * Returns the next voteable category, based on the last category voted.
   */
  nextVoteableCategory: ICategory;
}

export interface VotingGridProviderProps {
  children: React.ReactNode;
}

const VotingGridContext = createContext<VotingGridContextProps | null>(null);

export function VotingGridProvider({ children }: VotingGridProviderProps): JSX.Element {
  const { data: voteHistory } = useVoteHistory();
  const { data: snapshotSettings } = useWidget({
    select: (data: UseWidgetResponse) => data.snapshot.text.snapshot_settings,
  });
  const { category_wid = CAT_WID, contestants_wid = CONTESTANTS_WID } = snapshotSettings!;

  const [categoryHash] = useState(category_wid + getSidString('category_sid'));
  const { data: categories } = useWidget({
    widgetStateOptions: { ...WIDGET_STATE_OPTIONS, apiHash: categoryHash },
    refetchInterval: 5001,
    select: (data: UseWidgetResponse) => keyBy(data.snapshot.data, 'id'),
  });

  const votedCategoriesIds = Object.keys(voteHistory.history);
  const previouslyVotedCategoriesIds = Object.keys(voteHistory.previousHistory);

  const { language, languageCode } = useContext(DataContext);

  const [contestantHash] = useState(contestants_wid + getSidString('contestant_sid'));
  const { data: contestants } = useWidget({
    widgetStateOptions: { ...WIDGET_STATE_OPTIONS, apiHash: contestantHash },
    refetchInterval: 5001,
    select: (data: UseWidgetResponse) => data.snapshot.data,
  });

  const { categorySlug = '', voteOptionSlug = '' } = useParams();

  const categoriesBySlug: Record<CategoryId, ICategory> = useMemo(() => {
    return _.chain(contestants)
      .groupBy(x => x.catId)
      .map((value, key) => {
        value.sort((a, b) => a[`name_${language}`].localeCompare(b[`name_${language}`], languageCode));
        const voteOptions = keyBy(value, contestant => slugify(contestant.name));
        return { voteOptions, ...categories[key], slug: slugify(categories[key].name) };
      })
      .filter(cat => cat.name)
      .keyBy(cat => slugify(cat.name))
      .value();
  }, [categories, contestants, language, languageCode]);

  const allCategories = Object.values(categoriesBySlug);

  const activeCategory = categoriesBySlug[categorySlug];

  const allowedCategories = useMemo(
    () => allCategories.filter((cat: ICategory) => cat.languages.split(', ').includes(language)),
    [allCategories, language],
  );

  const activeCategoryIndex = activeCategory && allowedCategories.findIndex(el => el.id === activeCategory.id);

  const nextCategory = useMemo(() => {
    const index = (activeCategoryIndex + 1) % allowedCategories.length;
    return allowedCategories[index];
  }, [activeCategoryIndex, allowedCategories]);

  const previousCategory = useMemo(() => {
    const index = (activeCategoryIndex - 1 + allowedCategories.length) % allowedCategories.length;
    return allowedCategories[index];
  }, [activeCategoryIndex, allowedCategories]);

  const activeVoteOption = useMemo(() => activeCategory?.voteOptions[voteOptionSlug], [activeCategory, voteOptionSlug]);

  const isCategoryAllowed = (category: ICategory) => {
    const allowedLanguages = category?.languages?.split(',').map((lang: string) => lang.trim());
    return allowedLanguages?.includes(language);
  };

  const isCategoryVoted = (category: ICategory) => {
    return votedCategoriesIds.includes(category.id);
  };

  const votedCategories = Object.values(categoriesBySlug)
    .filter(isCategoryAllowed)
    .filter(isCategoryVoted)
    .map(category => {
      const { voteOptions, ...rest } = category;
      const catId = category.id;
      const votedOptionId = voteHistory.history[catId];
      const voteOptionsById = keyBy(Object.values(voteOptions), 'id');
      const votedOption = voteOptionsById[votedOptionId];

      if (!votedOption) return null;

      return { ...rest, voteOption: votedOption } as VotedCategory;
    })
    .filter((category): category is VotedCategory => !!category);

  const previouslyVotedCategories = Object.values(categoriesBySlug)
    .map(category => {
      const { voteOptions, ...rest } = category;
      const catId = category.id;
      const votedOptionId = voteHistory.previousHistory[catId];
      const voteOptionsById = keyBy(Object.values(voteOptions), 'id');
      const votedOption = voteOptionsById[votedOptionId];

      if (!votedOption) return null;

      return { ...rest, voteOption: votedOption } as VotedCategory;
    })
    .filter((category): category is VotedCategory => !!category);

  const firstVoteableCategory = allowedCategories.filter(cat => !votedCategoriesIds.includes(cat.id))[0];

  const areAllCategoriesVoted = votedCategories.length === allowedCategories.length;

  const hasPreviousBallot = previouslyVotedCategoriesIds.length > 0;
  const unvotedCategoryIds = allowedCategories.map(cat => cat.id).filter(id => !votedCategoriesIds.includes(id));

  const nextVoteableCategory = useMemo((): ICategory => {
    const lastCategoryIdVoted = voteHistory.lastCategoryVoted;

    if (!lastCategoryIdVoted) {
      return firstVoteableCategory;
    }

    const lastCategoryVotedIndex = allowedCategories.findIndex(cat => cat.id === lastCategoryIdVoted);

    // If the last category voted is not in the allowed categories, return the first voteable category
    if (lastCategoryVotedIndex === -1) {
      return firstVoteableCategory;
    }
    const nextCategories = allowedCategories
      .slice(lastCategoryVotedIndex + 1)
      .filter(cat => unvotedCategoryIds.includes(cat.id));

    const nextCategory = nextCategories.find(cat => voteHistory.history[cat.id] === undefined);

    if (!nextCategory) {
      return firstVoteableCategory;
    }

    return nextCategory;
  }, [allowedCategories, firstVoteableCategory, unvotedCategoryIds, voteHistory]);

  return (
    <VotingGridContext.Provider
      value={{
        categoriesBySlug,
        activeCategory,
        allowedCategories,
        nextCategory,
        previousCategory,
        activeVoteOption,
        isCategoryAllowed,
        votedCategories,
        previouslyVotedCategories,
        firstVoteableCategory,
        areAllCategoriesVoted,
        allCategories,
        hasPreviousBallot,
        nextVoteableCategory,
      }}
    >
      {children}
    </VotingGridContext.Provider>
  );
}

export function useVotingGrid() {
  const ctx = useContext(VotingGridContext);

  if (!ctx) {
    throw new Error(
      'useVotingGrid hook was called outside of context, wrap your component with VotingGridProvider component',
    );
  }

  return ctx;
}
