import { AuthModal, JudgeLogin, AudienceLogin } from 'features/auth';
import { useAuth, UserRole } from 'features/auth';

export const Auth = () => {
  const { role } = useAuth();

  return (
    <>
      {role === UserRole.JUDGE && (
        <AuthModal>
          <JudgeLogin />
        </AuthModal>
      )}
      {role === UserRole.AUDIENCE && (
        <AuthModal>
          <AudienceLogin />
        </AuthModal>
      )}
    </>
  );
};
