import {
  Button,
  ButtonProps,
  Flex,
  HStack,
  Heading,
  IconButton,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useBreakpoint,
} from '@telescope/cassini-ui';
import { DownloadIcon } from 'components';
import useShareStore from 'features/share/share-store';
import { useVotingGrid } from 'features/voting-app';
import { DataContext, UseWidgetResponse, useWidget } from 'providers';
import { useContext, useEffect, useRef, useState } from 'react';
import { exportAsImage } from '../utils/export-as-image';
import { SocialGraphic } from './SocialGraphic';
import { useTrackDownloads } from '../hooks/use-track-downloads';

type Orientation = 'horizontal' | 'vertical';

const DownloadButton = (props: ButtonProps) => {
  const breakpoint = useBreakpoint();

  if (breakpoint === 'base') {
    return <IconButton aria-label="Download" variant="primary" icon={<DownloadIcon />} {...props} />;
  }

  return (
    <Button variant="primary" leftIcon={<DownloadIcon />} {...props}>
      {props.children}
    </Button>
  );
};

export const ShareModal = () => {
  const { language } = useContext(DataContext);
  const { data: copy } = useWidget({ select: (data: UseWidgetResponse) => data.snapshot.text.share_modal });
  const { isOpen, toggleShare } = useShareStore();
  const [orientation, setOrientation] = useState<Orientation | null>(null);
  const { votedCategories } = useVotingGrid();
  const canvasRef = useRef<HTMLDivElement>(null);
  const [status, setStatus] = useState<'idle' | 'pending' | 'loading'>('idle');
  const isLoading = status === 'loading';
  const { trackDownload } = useTrackDownloads();

  const handleDownloadImage = async (orientation: Orientation) => {
    setOrientation(orientation);
    setStatus('pending');
    trackDownload({ orientation });
  };

  useEffect(() => {
    const downloadImage = async () => {
      if (status === 'pending') {
        setStatus('loading');
        await exportAsImage(canvasRef.current!, 'Crunchyroll Anime Awards 2024', {
          allowTaint: false,
          useCORS: true,
        });
        setOrientation(null);
        setStatus('idle');
      }
    };
    downloadImage();
  }, [status]);

  const onModalClose = () => {
    setOrientation(null);
    toggleShare();
  };

  return (
    <Modal isCentered isOpen={isOpen} onClose={onModalClose} size={{ base: 'full', md: 'sm' }}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader mb={12}>
          <Heading size="medium" mb={6} fontWeight={700}>
            {copy.headline[language]}
          </Heading>
          <Text textAlign="center" color="text.subtle" fontSize="sm">
            {copy.description[language]}
          </Text>
        </ModalHeader>
        <ModalBody>
          <SocialGraphic ref={canvasRef} votedCategories={votedCategories} orientation={orientation} />
          <Flex direction="column" gap={10}>
            <Flex alignItems="center">
              <HStack spacing={4} alignItems="center" flex={1}>
                <Image
                  w="130px"
                  src="https://content.votenow.tv/app/projects/crunchyroll/anime-awards-2024/images/ugc/AA24_UGC%20Ballots_Thumbnails_16x9_H_00.png"
                />
                <Text>{copy.horizontal_image[language]}</Text>
              </HStack>
              <DownloadButton
                data-ugc-download="horizontal"
                isLoading={isLoading && orientation === 'horizontal'}
                isDisabled={isLoading && orientation === 'vertical'}
                onClick={() => handleDownloadImage('horizontal')}
              >
                {copy.download_button_text[language]}
              </DownloadButton>
            </Flex>
            <Flex alignItems="center">
              <HStack spacing={4} alignItems="center" flex={1}>
                <Image
                  w="130px"
                  src="https://content.votenow.tv/app/projects/crunchyroll/anime-awards-2024/images/ugc/AA24_UGC%20Ballots_Thumbnails_16x9_V_00.png"
                />
                <Text>{copy.vertical_image[language]}</Text>
              </HStack>
              <DownloadButton
                data-ugc-download="vertical"
                isLoading={isLoading && orientation === 'vertical'}
                isDisabled={isLoading && orientation === 'horizontal'}
                onClick={() => handleDownloadImage('vertical')}
              >
                {copy.download_button_text[language]}
              </DownloadButton>
            </Flex>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
