import { ComponentStyleConfig } from '@chakra-ui/react';

export const drawerTheme: ComponentStyleConfig = {
  baseStyle: {
    dialog: {
      bg: 'Modal.bg',
      color: 'Modal.text',
      a: {
        color: 'Modal.link.text',
      },
      rounded: { base: 'none', md: 'xl' },
      pb: 0,
      px: 5,
      pt: 12,
    },
    header: {
      px: 0,
    },
    body: {
      p: 0,
    },
    footer: {
      px: 0,
    },
    closeButton: {
      fontSize: 'md',
      insetEnd: 'auto',
      insetStart: '3',
    },
  },
  sizes: {
    full: {
      dialog: {
        minH: 'var(--chakra-vh)',
        h: '100%',
      },
    },
  },
};
