import { ComponentStyleConfig } from '@chakra-ui/react';

export const textTheme: ComponentStyleConfig = {
  baseStyle: {
    '& a': {
      color: 'text.accent',
    },
  },
  sizes: {
    extraSmall: { fontSize: 10 },
    small: { fontSize: ['sm'] },
    medium: { fontSize: ['sm', 'md'] },
    large: { fontSize: ['md', 'lg'] },
  },
};
