import { useDisclosure } from '@telescope/cassini-hooks';
import {
  Button,
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  Wrap,
  WrapItem,
} from '@telescope/cassini-ui';
import { DataContext, UseWidgetResponse, useWidget } from 'providers';
import { useContext } from 'react';
import { useVotingGrid } from '../providers';
import { useResubmitStore, useResubmitVotes } from '../hooks';

export const ReviewSubmissionModal = () => {
  const { language } = useContext(DataContext);
  const { data: copy } = useWidget({ select: (data: UseWidgetResponse) => data.snapshot.text.review_submission_modal });
  const { isOpen, triggerType, closeReviewSubmission, resubmit, options } = useResubmitStore();

  const { previouslyVotedCategories } = useVotingGrid();
  const { resubmitVotes, isLoading } = useResubmitVotes();

  const data = previouslyVotedCategories.map(category => {
    const { voteOption } = category;
    return {
      name: category[`name_${language}`],
      thumbnail: voteOption.thumbnail,
    };
  });

  const handleResubmit = async () => {
    await resubmitVotes();
    resubmit();
    options?.onResubmit?.();
  };

  const handleDismiss = () => {
    closeReviewSubmission();
  };

  return (
    <Modal isCentered isOpen={isOpen} onClose={handleDismiss} size={{ base: 'full', md: 'md' }} scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent pb={{ base: 0, lg: 16 }} overflow={'hidden'}>
        <ModalCloseButton />
        <ModalHeader>
          <Heading size="medium" mb={6}>
            {copy[`headline_${triggerType}`][language]}
          </Heading>
          <Text textAlign="center" color="text.subtle" fontSize="sm">
            {copy[`description_${triggerType}`][language]}
          </Text>
        </ModalHeader>
        <ModalBody>
          <Wrap spacing={8} sx={{ ul: { pr: 4 } }} dir="ltr">
            {data.map((item, index) => (
              <WrapItem display="flex" alignItems="flex-end" position="relative" key={index}>
                <Image w={'80px'} src={item!.thumbnail} />
                <Text
                  transform="rotateZ(-90deg)"
                  transformOrigin={'left bottom'}
                  ml={'18px'}
                  as="span"
                  fontSize="10px"
                  fontWeight="bold"
                  position={'absolute'}
                  bottom={'0'}
                  left={'100%'}
                  whiteSpace="nowrap"
                  textOverflow={'ellipsis'}
                  overflow={'hidden'}
                  maxWidth={'120px'}
                >
                  {item!.name}
                </Text>
              </WrapItem>
            ))}
          </Wrap>
        </ModalBody>
        <ModalFooter
          justifyContent="center"
          sx={{
            '@media (max-width: 980px) ': {
              background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.00) 2.86%, rgba(0, 0, 0, 0.65) 24.96%, #000 99.83%)',
              pt: '120px',
              pb: '60px',
              px: 5,
              mx: -5,
            },
            '@media (max-width: 768px) ': {
              background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.00) 5.1%, rgba(0, 0, 0, 0.65) 31.73%, #000 99.83%)',
              pt: '170px',
            },
            '@media (max-width: 560px) ': {
              py: '40px',
            },
          }}
        >
          <Stack
            direction={{ base: 'column', md: 'row' }}
            justifyContent="center"
            color="primaryBase"
            borderColor="primaryBase"
            flex={1}
            spacing={4}
          >
            <Button variant="primary" onClick={handleResubmit} isLoading={isLoading}>
              {copy.submit_button_text[language]}
            </Button>
            <Button variant="primaryOutline" color="primaryBase" onClick={handleDismiss}>
              {copy.cancel_button_text[language]}
            </Button>
          </Stack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
