import { ModalProps } from '@telescope/cassini-ui';
import { create } from 'zustand';

type ReviewSubmissionModalProps = Omit<ModalProps, 'isOpen' | 'children' | 'onClose'> & {
  onResubmit?: () => void;
};
type TriggerType = 'direct_click' | 'vote_flow';

type ResubmitStore = {
  isOpen: boolean;
  hasBeenDismissed: boolean;
  hasBeenSubmitted: boolean;
  isPendingAuth: boolean;
  triggerType: TriggerType;
  options?: ReviewSubmissionModalProps;
  showReviewSubmission: (triggerType: TriggerType, options?: ReviewSubmissionModalProps) => void;
  closeReviewSubmission: () => void;
  setPendingAuth: (isPendingAuth: boolean) => void;
  setHasBeenSubmitted: (hasBeenSubmitted: boolean) => void;
  setHasBeenDismissed: (hasBeenDismissed: boolean) => void;
  resubmit: () => void;
};

export const useResubmitStore = create<ResubmitStore>(set => ({
  isPendingAuth: false,
  hasBeenDismissed: false,
  hasBeenSubmitted: false,
  triggerType: 'direct_click',
  options: undefined,
  isOpen: false,
  closeReviewSubmission: () => set({ isOpen: false, hasBeenDismissed: true }),
  showReviewSubmission: (triggerType: TriggerType, options?: ReviewSubmissionModalProps) =>
    set({ triggerType, options, isOpen: true }),
  setPendingAuth: isPendingAuth => set({ isPendingAuth }),
  setHasBeenSubmitted: hasBeenSubmitted => set({ hasBeenSubmitted }),
  setHasBeenDismissed: hasBeenDismissed => set({ hasBeenDismissed }),
  resubmit: () => set({ hasBeenSubmitted: true, isOpen: false, hasBeenDismissed: true }),
}));
