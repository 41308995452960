import { Flex, HStack } from '@telescope/cassini-ui';
import { UseWidgetResponse, useWidget } from 'providers';
import { GenericObject } from 'types';
import { NavLink } from './NavLink';

export const NavBanner = () => {
  const { data: links } = useWidget({ select: (data: UseWidgetResponse) => data.snapshot.text.navigation });

  return (
    <HStack
      as={Flex}
      w="100%"
      p={[2, 5]}
      bg="NavBanner.bg"
      spacing="60px"
      justifyContent="center"
      color="NavBanner.text"
    >
      {links.map((link: GenericObject) => (
        <NavLink data={link} key={link.link} />
      ))}
    </HStack>
  );
};
