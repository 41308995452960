import { ClosedRoutes, VotingAppRoutes } from 'features';
import { UseWidgetResponse, useWidget } from 'providers';
import { Route, Routes } from 'react-router-dom';
import { SnapshotType } from 'types';

const CurrentSnapshot = () => {
  const { data } = useWidget({ select: (data: UseWidgetResponse) => data.snapshot });

  const snapshotType = data.text.snapshot_settings.snapshot_type as SnapshotType;

  switch (snapshotType) {
    case SnapshotType.PRE_VOTE_CLOSED:
      return <ClosedRoutes />;
    // Open and Post Vote Closed are nearly the same experience. Post Vote Closed just doesn't allow voting, so some UI elements are hidden.
    case SnapshotType.OPEN:
    case SnapshotType.POST_VOTE_CLOSED:
      return <VotingAppRoutes />;
    default:
      return <div>Invalid Snapshot type</div>;
  }
};

export const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/*" element={<CurrentSnapshot />} />
    </Routes>
  );
};
