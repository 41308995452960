import React, { useMemo } from 'react';
import { useVotingGrid } from '../providers';
import { CategoryButton } from './CategoryButton';
import { ICategory } from 'types';

interface CategoriesListProps {
  WrapItem?: JSX.Element;
  onCategoryClick?: (category: ICategory) => void;
}
export const CategoriesList = (props: CategoriesListProps) => {
  const { WrapItem } = props;
  const { allowedCategories } = useVotingGrid();
  const categoriesArray = Object.entries(allowedCategories).map(([slug, category]) => (
    <CategoryButton category={category} key={slug} onClick={() => props.onCategoryClick?.(category)} />
  ));

  const _children = useMemo(
    () =>
      WrapItem
        ? categoriesArray.map((categoryItem, index) => React.cloneElement(WrapItem, categoryItem))
        : categoriesArray,
    [categoriesArray, WrapItem],
  );

  return <>{_children}</>;
};
