import { Flex, HStack, Link, Text } from '@telescope/cassini-ui';
import { LogoIconLarge } from 'components';
import { DataContext, UseWidgetResponse, useWidget } from 'providers';
import { useContext } from 'react';

export const HeaderBanner = () => {
  const { language } = useContext(DataContext);
  const { data } = useWidget({ select: (data: UseWidgetResponse) => data.snapshot.text.header });

  return (
    <HStack
      h="60px"
      w="100%"
      bg="HeaderBanner.bg"
      p="0 18px"
      align="center"
      spacing="34px"
      fontFamily="Lato, sans-serif"
      color="HeaderBanner.text"
    >
      <Link isExternal href={data.logo.link} data-group>
        <LogoIconLarge fill="primaryBase" _groupHover={{ fill: '#fff' }} />
      </Link>

      <Flex h="100%" align="stretch">
        {data.links.map((link: Record<string, any>) => (
          <Flex
            as={Link}
            isExternal
            href={link.url}
            key={link.url}
            _hover={{
              color: 'HeaderBanner.link.text--hover',
              bg: 'HeaderBanner.link.bg--hover',
              textDecoration: 'none',
            }}
            _focusVisible={{
              color: '#fff',
              bg: 'HeaderBanner.link.bg--hover',
              boxShadow: 'none',
              outline: '.25rem solid #a0a0a0',
              outlineOffset: '-0.25rem',
            }}
            fontSize="16px"
            fontWeight={500}
            px="14px"
            align="center"
          >
            <Text as="span"> {link.copy[language]} </Text>
          </Flex>
        ))}
      </Flex>
    </HStack>
  );
};
