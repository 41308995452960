import { useDisclosure } from '@telescope/cassini-hooks';
import { Stack, Box, Modal, ModalOverlay, ModalContent, ModalBody, ModalCloseButton } from '@telescope/cassini-ui';
import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

export interface AuthModalProps {
  children: React.ReactNode;
}

export const AuthModal = (props: AuthModalProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isOpen, onClose } = useDisclosure({ defaultIsOpen: true });

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      onCloseComplete={() => navigate(location.state?.backgroundLocation || '..')}
      scrollBehavior="inside"
      size={{ base: 'full', md: 'md' }}
      isCentered
    >
      <ModalOverlay />
      <ModalContent pb={{ base: 0, sm: 16 }}>
        <ModalCloseButton size="lg" />
        <ModalBody>
          <Box>
            <Stack alignItems="center" spacing={3} mb={5}>
              {props.children}
            </Stack>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
