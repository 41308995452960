const baseStyle = {
  color: 'text.subtle',
  mb: '1.5',
};

const sizes = {
  sm: {
    fontSize: 'sm',
    _peerPlaceholderShown: {
      fontSize: 'sm',
      top: '1.5',
    },
  },
  md: {
    fontSize: 'md',
    _peerPlaceholderShown: {
      fontSize: 'md',
      top: '2',
    },
  },
  lg: {
    fontSize: 'sm',
    _peerPlaceholderShown: {
      fontSize: 'lg',
      top: '2.5',
    },
  },
};

const variants = {
  inline: () => ({
    margin: 0,
    minW: '2xs',
  }),
  floating: () => ({
    position: 'absolute',
    transition: 'all 0.12s ease-in',
    pointerEvents: 'none',
    top: '-17px',
    left: '0',
    _peerPlaceholderShown: {
      fontWeight: 'normal',
      color: 'fg.subtle',
    },
    _peerFocus: {
      fontSize: 'sm',
      fontWeight: 'medium',
      top: '-17px',
      left: '0',
      color: 'fg.muted',
    },
  }),
};

const defaultProps = {
  size: 'md',
};

export const formLabelTheme = {
  baseStyle,
  sizes,
  variants,
  defaultProps,
};
