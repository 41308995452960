import { initReactQueryAuth } from '../providers/AuthProvider';
import { Spinner } from '@telescope/cassini-ui';
import { AuthUser } from 'features/auth';

const authConfig = {
  LoaderComponent() {
    return <Spinner />;
  },
};

// TODO: update types to include access code and registration
export const { AuthProvider, useAuth } = initReactQueryAuth<
  AuthUser | null,
  unknown
>(authConfig);
