import { Button, Flex, Text } from '@telescope/cassini-ui';
import { ExitIcon } from 'components';
import { useSegment } from 'features/analytics/useSegment';
import { useAuth } from 'features/auth';
import { DataContext, UseWidgetResponse, useWidget } from 'providers';
import { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { SnapshotType } from 'types';
import { ROUTES } from 'utils';

export const AuthNav = () => {
  const { logout, isAuthenticated } = useAuth();
  const { data } = useWidget({ select: (data: UseWidgetResponse) => data.snapshot.text.login });
  const { language } = useContext(DataContext);
  const buttonText = isAuthenticated ? data.logout_cta[language] : data.login_cta[language];
  const navigate = useNavigate();
  const location = useLocation();
  const { reset } = useSegment();
  const { data: snapshotType } = useWidget({
    select: (data: UseWidgetResponse) => data.snapshot.text.snapshot_settings.snapshot_type,
  });
  const shouldShowAuthNav = snapshotType === SnapshotType.OPEN;

  useEffect(() => {
    // logout user if the snapshot type changes to pre-vote closed or post-vote closed
    if (snapshotType !== SnapshotType.OPEN && isAuthenticated) {
      logout();
    }
  }, [snapshotType, logout, isAuthenticated]);

  const handleAuthClick = async () => {
    if (isAuthenticated) {
      reset();
      return await logout();
    }

    return navigate(ROUTES.AUTH, { state: { backgroundLocation: location } });
  };

  return (
    <Flex className="AuthNav__root" justifyContent={'flex-end'}>
      <Button
        visibility={shouldShowAuthNav ? 'visible' : 'hidden'}
        rounded={0}
        className="AuthNav__button"
        onClick={handleAuthClick}
        variant="link"
        color="white"
        fontSize="12px"
        fontWeight={800}
        _active={{ color: 'white' }}
        leftIcon={<ExitIcon />}
      >
        <Text className="AuthNav__cta">{buttonText}</Text>
      </Button>
    </Flex>
  );
};
