import { chakra } from '@telescope/cassini-ui';
import { ImageProps as ChakraImageProps } from '@chakra-ui/react';
import React from 'react';
import { LazyLoadImage, LazyLoadImageProps } from 'react-lazy-load-image-component';
import { ImagePlaceholderIcon } from 'components/icons';

import 'react-lazy-load-image-component/src/effects/blur.css';

const LazyImage = chakra(LazyLoadImage);

interface ImageProps extends ChakraImageProps {
  lazyLoadImageProps?: LazyLoadImageProps;
}

export const Image = ({ lazyLoadImageProps, ...rest }: ImageProps) => {
  return <LazyImage effect={'blur'} placeholder={<ImagePlaceholderIcon />} {...rest} {...lazyLoadImageProps} />;
};
