import {
  Button,
  ButtonGroup,
  ButtonGroupProps,
  ButtonProps,
  Heading,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Text,
} from '@telescope/cassini-ui';
import React from 'react';
import { ConfirmLabels } from './modals-context';
import { useModal } from './use-modal';

export interface ConfirmModalProps {
  id?: string;
  body?: React.ReactNode;
  header?: React.ReactNode;
  description?: React.ReactNode;
  onCancel?(): void;
  onConfirm?(): void;
  closeOnConfirm?: boolean;
  closeOnCancel?: boolean;
  cancelProps?: ButtonProps & React.ComponentPropsWithoutRef<'button'>;
  confirmProps?: ButtonProps & React.ComponentPropsWithoutRef<'button'>;
  groupProps?: ButtonGroupProps;
  labels?: ConfirmLabels;
}

export function ConfirmModal({
  id,
  cancelProps,
  confirmProps,
  labels = { cancel: 'Cancel', confirm: 'Confirm' },
  closeOnConfirm = true,
  closeOnCancel = true,
  groupProps,
  onCancel,
  onConfirm,
  body,
  header,
  description,
}: ConfirmModalProps) {
  const { cancel: cancelLabel, confirm: confirmLabel } = labels;
  const ctx = useModal();
  const [isConfirming, setIsConfirming] = React.useState(false);

  const handleCancel = (event: React.MouseEvent<HTMLButtonElement>) => {
    typeof cancelProps?.onClick === 'function' && cancelProps?.onClick(event);
    typeof onCancel === 'function' && onCancel();
    closeOnCancel && ctx.closeModal(id!);
  };

  const handleConfirm = async (event: React.MouseEvent<HTMLButtonElement>) => {
    setIsConfirming(true);
    typeof confirmProps?.onClick === 'function' && confirmProps?.onClick(event);
    typeof onConfirm === 'function' && (await onConfirm());
    setIsConfirming(false);
    closeOnConfirm && ctx.closeModal(id!);
  };

  return (
    <>
      {header && <ModalHeader>{header}</ModalHeader>}
      <ModalBody>{body}</ModalBody>

      <ModalFooter justifyContent="center">
        <ButtonGroup isDisabled={isConfirming} {...groupProps}>
          <Button isLoading={isConfirming} variant="primary" {...confirmProps} onClick={handleConfirm}>
            {confirmLabel}
          </Button>
          <Button variant="outline" colorScheme="orange" {...cancelProps} onClick={handleCancel}>
            {cancelLabel}
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </>
  );
}
