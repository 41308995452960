import { AppShell, VotingShell } from 'components';
import { Auth, CategoryNominees, ErrorModal, NomineeDetail, ReviewSubmissionModal } from 'features';
import useShareStore from 'features/share/share-store';
import { Outlet, Route, Routes, useLocation } from 'react-router-dom';
import { ROUTES } from 'utils';
import { ShareModal } from '../../share/components/ShareModal';
import { CategoriesGrid } from '../components/CategoriesGrid';
import { VotingGridProvider } from '../providers';

export const VotingAppRoutes = () => {
  const location = useLocation();
  const backgroundLocation = location.state?.backgroundLocation;
  const { isOpen, toggleShare } = useShareStore();

  return (
    <>
      <Routes location={backgroundLocation || location}>
        <Route
          element={
            <VotingGridProvider>
              <Outlet />
              <ShareModal />
              <ErrorModal />
              <ReviewSubmissionModal />
            </VotingGridProvider>
          }
        >
          <Route
            index
            element={
              <AppShell>
                <CategoriesGrid />
              </AppShell>
            }
          ></Route>
          <Route
            path={`${ROUTES.CATEGORY}/*`}
            element={
              <VotingShell>
                <CategoryNominees />
              </VotingShell>
            }
          >
            <Route path={ROUTES.VOTE_OPTION} element={<NomineeDetail />} />
          </Route>
        </Route>
      </Routes>

      {backgroundLocation && (
        <Routes>
          <Route path={ROUTES.AUTH} element={<Auth />} />
        </Routes>
      )}
    </>
  );
};
