import { isMobile } from 'react-device-detect';
import { GenericObject } from 'types';
import stringToSlug from 'slugify';

export const getLanguageSuffix = (languageCode: string) => {
  const code = languageCode.split('-')[0];

  switch (code) {
    case 'en':
      return code;
    case 'ja':
      return code;
    case 'es':
      if (languageCode === 'es-ES') {
        return 'esc';
      } else {
        return code;
      }
    case 'fr':
      return code;
    case 'ar':
      return code;
    case 'de':
      return code;
    case 'it':
      return code;
    case 'pt':
      return code;
    default:
      return 'en';
  }
};

export const getTextDirection = (language: string) => {
  return language === 'ar' ? 'rtl' : 'ltr';
};

const toCamel = (s: any) => {
  return s.replace(/([-_][a-z])/gi, ($1: any) => {
    return $1.toUpperCase().replace('-', '').replace('_', '');
  });
};

const isArray = function (a: any) {
  return Array.isArray(a);
};

const isObject = function (o: any) {
  return o === Object(o) && !isArray(o) && typeof o !== 'function';
};

export const normalizeStyles = function (o: any) {
  if (isObject(o)) {
    const n: { [key: string]: any } = {};

    Object.keys(o).forEach(k => {
      if (k === 'background_image') {
        o[k] = o[k] && `url(${o[k]})`;
      }

      if (k === 'hover' && !isMobile) {
        const key = ':hover';
        o[key] = o[k];
        k = key;
      }

      if (k === 'active') {
        const key = ':active';
        o[key] = o[k];
        k = key;
      }

      if (o[k] !== '') {
        n[toCamel(k)] = normalizeStyles(o[k]);
      }
    });

    return n;
  } else if (isArray(o)) {
    return o.map((i: any) => {
      return normalizeStyles(i);
    });
  }

  return o;
};

export const insertFonts = (fontString: string) => {
  if (!fontString) {
    return;
  }

  const fontsArray = fontString.split(',');

  fontsArray.forEach((url: string) => {
    if (url) {
      const fontLink = document.createElement('link');
      document.head.appendChild(fontLink);
      fontLink.rel = 'stylesheet';
      fontLink.setAttribute('crossorigin', 'anonymous');
      fontLink.href = url.trim();
    }
  });
};

export const isEmpty = (obj: GenericObject) => {
  return Object.keys(obj).length === 0;
};

export const slugify = (str: string): string => {
  if (!str) return '';
  return stringToSlug(str, {
    replacement: '_',
    lower: true,
    strict: true,
    trim: true,
  });
};
