import { AnalyticsBrowser } from '@segment/analytics-next';
import { AuthUser, useAuth } from 'features/auth';
import { DataContext } from 'providers';
import { useCallback, useContext } from 'react';
import { useUtmParams } from './useUtmParams';

const segment = AnalyticsBrowser.load({
  writeKey: window.tsReactInitConfig.segmentWriteKey,
});

export const useSegment = () => {
  const { role } = useAuth();
  const { user } = useAuth();
  const { languageCode } = useContext(DataContext);
  const utmParams = useUtmParams();
  const isJudge = role === 'judge';
  const userData = user?.user;

  const pageEvent = useCallback(
    (...args: Parameters<AnalyticsBrowser['page']>) => {
      if (isJudge) {
        return;
      }

      segment.page(...args);
    },
    [isJudge],
  );

  const trackEvent = useCallback(
    (...args: Parameters<AnalyticsBrowser['track']>) => {
      if (isJudge) {
        return;
      }

      segment.track(...args);
    },
    [isJudge],
  );

  const identifyUser = useCallback(
    (user: Pick<AuthUser, 'user'>) => {
      if (isJudge) {
        return;
      }

      const {
        user: { method, user_id },
      } = user;
      const userTraits = {
        phone: method === 'phone' ? user_id : '',
        email: method === 'email' ? user_id : '',
      };
      segment.identify(user_id, userTraits);
    },
    [isJudge],
  );

  const trackVote = useCallback(
    (categoryName: string) => {
      if (isJudge || !userData) {
        return;
      }

      const { method, user_id } = userData;
      const userTraits = {
        telephone: method === 'phone' ? user_id : '',
        email: method === 'email' ? user_id : '',
      };
      const country = window.evidon?.notice?.country?.code ?? '';

      segment.track('Anime Awards Voted', {
        eventSource: 'cr_vod_animeawards',
        country: country,
        categoryName: categoryName,
        language: languageCode.toLowerCase(),
        ...userTraits,
        ...utmParams,
      });
    },
    [isJudge, languageCode, userData, utmParams],
  );

  return {
    pageEvent,
    trackEvent,
    identifyUser,
    trackVote,
    reset: segment.reset,
  };
};
