import { useDisclosure } from '@telescope/cassini-hooks';
import { Box, Button, Divider, Flex, Grid, GridItem, Heading, IconButton, Show, Tooltip } from '@telescope/cassini-ui';
import { InfoIcon, ShareIcon } from 'components';
import { NomineeCard, useAuth, useErrorStore } from 'features';
import { useSegment } from 'features/analytics/useSegment';
import useShareStore from 'features/share/share-store';
import { useVotingGrid } from 'features/voting-app';
import { DataContext, UseWidgetResponse, useWidget } from 'providers';
import { useContext, useEffect, useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import { Navigate, Outlet, useNavigate, useParams } from 'react-router-dom';
import { ErrorType } from 'types';
import { ROUTES } from 'utils';
import parse from 'html-react-parser';

export const CategoryNominees = () => {
  const { role } = useAuth();
  const { categorySlug } = useParams();
  const { language } = useContext(DataContext);
  const { categoriesBySlug, isCategoryAllowed, activeVoteOption, activeCategory, votedCategories } = useVotingGrid();
  const category = categoriesBySlug[categorySlug!];
  const isAllowed = isCategoryAllowed(category);
  const navigate = useNavigate();
  const { showError } = useErrorStore();
  const { toggleShare } = useShareStore();
  const footerRef = useRef<HTMLElement | null>(null);
  const isAudienceRole = role === 'audience';
  const shouldShowShareButton = votedCategories.length > 0 && isAudienceRole;
  const { data: shareButtonText } = useWidget({
    select: (data: UseWidgetResponse) => data.snapshot.text.share_modal.cta,
  });
  const isRtl = language === 'ar';

  const { ref: inViewRef, inView } = useInView({ threshold: 0 });

  const { isOpen, onOpen, onToggle, onClose } = useDisclosure();
  const { pageEvent } = useSegment();

  useEffect(() => {
    if (category && !isAllowed) {
      showError(ErrorType.LANGUAGE, {
        onCloseComplete: () => {
          navigate(ROUTES.HOME);
        },
      });
    }
  }, [category, isAllowed, navigate, showError]);

  useEffect(() => {
    pageEvent('Category');
  }, [pageEvent]);

  // Get a reference to the footer element and trigger the inViewRef callback. This will check if the footer is in view
  useEffect(() => {
    if (!footerRef.current) {
      footerRef.current = document.querySelector('footer');
      inViewRef(footerRef.current);
    }
  }, [inViewRef]);

  if (!category) {
    return <Navigate to=".." />;
  }

  return (
    <Box as="section" position="relative">
      {shouldShowShareButton && (
        <Button
          variant="secondary"
          size="sm"
          leftIcon={<ShareIcon />}
          hideFrom="lg"
          position={inView ? 'absolute' : 'fixed'}
          bottom={inView ? '-40px' : 6}
          right={isRtl ? 'auto' : inView ? 0 : 6}
          left={isRtl ? (inView ? 0 : 6) : 'auto'}
          onClick={toggleShare}
          zIndex={1000}
        >
          {shareButtonText[language]}
        </Button>
      )}
      {activeVoteOption ? (
        <Outlet />
      ) : (
        <Flex hideBelow={'md'} justifyContent="center" alignItems="center" gap={2} mt={8}>
          <Heading fontWeight={700}> {parse(activeCategory[`name_${language}`])}</Heading>
          <Tooltip
            hasArrow
            label={activeCategory[`description_${language}`]}
            bg="trout"
            color="white"
            rounded="md"
            fontSize="md"
            px={3}
            py={2}
            isOpen={isOpen}
          >
            <span>
              <IconButton
                variant="unstyled"
                onClick={onToggle}
                onMouseEnter={onOpen}
                onMouseLeave={onClose}
                icon={<InfoIcon />}
                aria-label=""
              />
            </span>
          </Tooltip>
        </Flex>
      )}

      <Show above="md">
        <Divider borderColor="shark" opacity={1} mt={12} mb={6} />
      </Show>
      <Grid templateColumns={{ base: '1fr', md: 'repeat(2, minmax(0, 1fr))' }} gap={[4, 4, 10]} justifyItems="center">
        {Object.values(category.voteOptions).map(voteOption => (
          <GridItem key={voteOption.id} w="100%" minW={0}>
            <NomineeCard voteOption={voteOption} />
          </GridItem>
        ))}
      </Grid>
    </Box>
  );
};
