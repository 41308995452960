import { create } from 'zustand';

type ShareStore = {
  isOpen: boolean;
  toggleShare: () => void;
  closeShare: () => void;
  openShare: () => void;
};

const useShareStore = create<ShareStore>(set => ({
  isOpen: false,
  toggleShare: () => set(state => ({ isOpen: !state.isOpen })),
  closeShare: () => set({ isOpen: false }),
  openShare: () => set({ isOpen: true }),
}));

export default useShareStore;
