import { camelCase } from 'change-case';
import { merge } from 'merge-anything';
import { useStorage } from 'providers';
import { useEffect, useState } from 'react';
import { GenericObject } from 'types';

const UTM_STORAGE_KEY = 'utm';

type UtmParams = Partial<Record<'utmCampaign' | 'utmContent' | 'utmMedium' | 'utmSource' | 'utmTerm', string>>;

/**
 * The url may contain utm params used for analytics purposes. This function will return an object with any utm params found in the url.
 */
const getUtmParams = () => {
  const allowedParams = ['utm_campaign', 'utm_content', 'utm_medium', 'utm_source', 'utm_term'];
  const searchParams = new URLSearchParams(window.location.search);
  const utmParams: GenericObject = {};

  searchParams.forEach((value, key) => {
    if (allowedParams.includes(key.toLowerCase())) {
      utmParams[camelCase(key)] = value;
    }
  });
  return utmParams;
};

let isInitialized = false;

const initialUtmParams = getUtmParams();

export const useUtmParams = () => {
  const storage = useStorage();
  const paramsFromStorage = storage.getItem(UTM_STORAGE_KEY);
  const [utmParams, setUtmParams] = useState<UtmParams>(paramsFromStorage);

  // On initial page load, merge the utm params from the URL with the ones from storage. New params in the URL will override the ones from storage.
  useEffect(() => {
    if (isInitialized) {
      return;
    }
    const mergedParams = merge(paramsFromStorage, initialUtmParams);
    storage.setItem(UTM_STORAGE_KEY, mergedParams);
    isInitialized = true;
    setUtmParams(mergedParams);
  }, [storage, setUtmParams, paramsFromStorage]);

  return utmParams;
};
