import { ComponentStyleConfig } from '@chakra-ui/react';

export const containerTheme: ComponentStyleConfig = {
  sizes: {
    app: {
      maxW: 'container.appMax',
      px: 5,
      py: 5,
    },
  },
  defaultProps: {
    size: 'app',
  },
};
