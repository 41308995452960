import { ModalProps } from '@telescope/cassini-ui';
import { create } from 'zustand';

type ErrorModalOptions = Omit<ModalProps, 'isOpen' | 'children' | 'onClose'>;

type ErrorStore = {
  error?: string | null;
  options?: ErrorModalOptions;
  showError: (error: string, options?: ErrorModalOptions) => void;
  clearError: () => void;
};

export const useErrorStore = create<ErrorStore>(set => ({
  error: null,
  options: undefined,
  showError: (error: string, options?: ErrorModalOptions) => set({ error, options }),
  clearError: () => set({ error: null }),
}));
