import { Navigate, Route, Routes } from 'react-router-dom';
import { AppShell } from 'components';
import { PreVoteClosedPage } from '../components/PreVoteClosedPage';
import { ROUTES } from 'utils';

export const ClosedRoutes = () => {
  return (
    <Routes>
      <Route
        index
        element={
          <AppShell>
            <PreVoteClosedPage />
          </AppShell>
        }
      />
      <Route path="*" element={<Navigate to={ROUTES.HOME} />} />
    </Routes>
  );
};
