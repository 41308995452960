import { useDisclosure } from '@telescope/cassini-hooks';
import {
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  SimpleGrid,
  useBreakpointValue,
} from '@telescope/cassini-ui';
import { DataContext, UseWidgetResponse, useWidget } from 'providers';
import React, { useContext } from 'react';
import { CategoriesList } from './CategoriesList';

interface CategoriesMenuProps {
  Trigger: React.ReactElement;
}

const BodyContent = (props: React.PropsWithChildren) => (
  <SimpleGrid w="100%" columns={{ base: 1, md: 2, lg: 4 }} columnGap={7} rowGap={4} gridAutoRows={'1fr'}>
    {props.children}
  </SimpleGrid>
);

export const CategoriesMenu = (props: CategoriesMenuProps) => {
  const { Trigger } = props;
  const showAsModal = useBreakpointValue({ base: true, lg: false });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { language } = useContext(DataContext);
  const { data } = useWidget({ select: (data: UseWidgetResponse) => data.snapshot.text.categories_navigation });

  if (showAsModal) {
    return (
      <>
        {React.cloneElement(Trigger, { onClick: onOpen })}
        <Modal size="full" isOpen={isOpen} onClose={onClose} scrollBehavior="inside">
          <ModalOverlay />
          <ModalContent p={0} overflow={'hidden'} bg="CategoriesMenu.body.bg" rounded={0}>
            <ModalCloseButton top="18px" />
            <ModalHeader py={5} borderBottom={'1px'}>
              <Heading size="medium" textAlign="inherit" px={{ base: 5, md: 10 }}>
                {data.all_categories_label[language]}
              </Heading>
            </ModalHeader>
            <ModalBody pt={{ base: 6, md: 12 }} px={{ base: 5, md: 14 }}>
              <BodyContent>
                <CategoriesList onCategoryClick={onClose} />
              </BodyContent>
            </ModalBody>
          </ModalContent>
        </Modal>
      </>
    );
  }

  return (
    <Popover>
      {({ onClose }) => (
        <>
          <PopoverTrigger>{Trigger}</PopoverTrigger>
          <PopoverContent w="full" maxW="container.appMax" borderColor="CategoriesMenu.body.bg">
            <PopoverBody p={4} bg="CategoriesMenu.body.bg">
              <BodyContent>
                <CategoriesList onCategoryClick={onClose} />
              </BodyContent>
            </PopoverBody>
          </PopoverContent>
        </>
      )}
    </Popover>
  );
};
