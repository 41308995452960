import { UseQueryOptions, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { VoteAPIResponseCode, useVote as useVoteApi } from '@telescope/cassini-hooks';
import { useAuth } from 'features';
import { LAST_VOTE_LOCALSTORAGE_KEY, QueryKey } from 'features/auth/utils/constants';
import { useStorage } from 'providers';
import { ACTION_TYPES } from 'utils';

export type CategoryId = string;
export type VoteOptionId = string;
export type VoteHistoryRecord = Record<CategoryId, VoteOptionId>;
export type VoteHistory = {
  history: VoteHistoryRecord;
  previousHistory: VoteHistoryRecord;
  lastCategoryVoted?: CategoryId;
};
export type SubmitVotePayload = {
  category: string;
  contestant: string;
};

const initialVoteHistory = {
  history: {},
  previousHistory: {},
};

export const useVoteHistory = (options?: Pick<UseQueryOptions<VoteHistory>, 'select'>) => {
  const { user, isAuthenticated } = useAuth();
  const vote = useVoteApi();
  const storage = useStorage();

  const { data, ...rest } = useQuery<VoteHistory, any>({
    queryKey: [QueryKey.VOTE_HISTORY],
    queryFn: async (): Promise<VoteHistory> => {
      const response = await vote({
        method: user?.user.method,
        user_id: user?.user.user_id,
        action_type: 'get',
      });
      const history: VoteHistory = {
        history: {},
        previousHistory: {},
        lastCategoryVoted: storage.getItem(LAST_VOTE_LOCALSTORAGE_KEY),
      };

      if (response.response_code !== VoteAPIResponseCode.VALID) {
        console.error('useVoteHistory: failed to fetch vote history - response_code:', response.response_code);
        return history;
      }

      const votestring = JSON.parse(response.votestring || '{}');
      const previous_votestring = JSON.parse(response.previous_votestring || '{}');
      history.history = votestring;
      history.previousHistory = previous_votestring;

      return history;
    },
    enabled: isAuthenticated,
    staleTime: Infinity,
    refetchOnWindowFocus: 'always',
    placeholderData: initialVoteHistory,
    retry: false,
    ...options,
  });

  return {
    data: data as VoteHistory,
    ...rest,
  };
};
export const useSubmitVote = () => {
  const { user } = useAuth();
  const vote = useVoteApi();
  const queryClient = useQueryClient();
  const storage = useStorage();

  const { mutateAsync, ...rest } = useMutation({
    mutationFn: async (payload: SubmitVotePayload) => {
      const response = await vote({
        ...payload,
        method: user?.user.method,
        user_id: user?.user.user_id,
        action_type: ACTION_TYPES.VOTE,
      });

      if (response.response_code === VoteAPIResponseCode.VALID) {
        return response;
      }

      throw new Error(VoteAPIResponseCode.WINDOW);
    },
    onSuccess: (data, variables) => {
      console.log('onSuccess', data, variables);

      if (data.response_code === VoteAPIResponseCode.VALID) {
        const voteHistory = {
          history: JSON.parse(data.votestring || '{}'),
          previousHistory: JSON.parse(data.previous_votestring || '{}'),
          lastCategoryVoted: variables.category,
        };
        storage.setItem(LAST_VOTE_LOCALSTORAGE_KEY, variables.category);
        return queryClient.setQueryData([QueryKey.VOTE_HISTORY], voteHistory);
      }

      throw new Error(data.response_code);
    },
    onError: (error, variables) => {
      console.log('onError', error, variables);
    },
  });

  return {
    submitVote: mutateAsync,
    ...rest,
  };
};

export const useResubmitVotes = () => {
  const { user } = useAuth();
  const vote = useVoteApi();
  const queryClient = useQueryClient();

  const { mutateAsync, ...rest } = useMutation({
    mutationFn: async () => {
      const response = await vote({
        method: user?.user.method,
        user_id: user?.user.user_id,
        action_type: ACTION_TYPES.SUBMIT_PREVIOUS_VOTES,
      });

      if (response.response_code === VoteAPIResponseCode.VALID) {
        return response;
      }

      throw new Error(response.response_code);
    },
    onSuccess: (data, variables) => {
      console.log('onSuccess', data, variables);

      if (data.response_code === VoteAPIResponseCode.VALID) {
        const voteHistory = {
          history: JSON.parse(data.votestring || '{}'),
          previousHistory: JSON.parse(data.previous_votestring || '{}'),
        };

        return queryClient.setQueryData([QueryKey.VOTE_HISTORY], voteHistory);
      }

      throw new Error(data.response_code);
    },
    onError: (error, variables) => {
      console.log('onError', error, variables);
    },
  });

  return {
    resubmitVotes: mutateAsync,
    ...rest,
  };
};
