import {
  Box,
  Button,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Spacer,
  Text,
} from '@telescope/cassini-ui';
import { ExclamationCircleIcon } from 'components';
import { DataContext, UseWidgetResponse, useWidget } from 'providers';
import { useCallback, useContext } from 'react';
import { useErrorStore } from './error-store';

export const ErrorModal = () => {
  const { data } = useWidget({ select: (data: UseWidgetResponse) => data.snapshot.text.error_messages });
  const { language } = useContext(DataContext);
  const { error, clearError, options } = useErrorStore();

  const getErrorMessage = useCallback(() => {
    const headline = error ? data[error].headline[language] : data.generic.headline[language];
    const description = error ? data[error].description[language] : '';
    const buttonText = error ? data[error].button_text[language] : data.generic.button_text[language];

    return { headline, description, buttonText };
  }, [data, error, language]);

  const message = getErrorMessage();

  const handleCloseComplete = () => {
    options?.onCloseComplete?.();
  };

  return (
    <Modal isOpen={!!error} onClose={clearError} isCentered size="sm" onCloseComplete={handleCloseComplete}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton size="lg" />
        <ModalBody>
          <Box mt={'80px'} mb={10}>
            <Flex direction="column" align="center">
              <Box mb={10} textAlign="center" sx={{ svg: { display: 'inline-block' } }}>
                <ExclamationCircleIcon mb={3} />
                <Heading size="medium" color="white" mb={3}>
                  {message.headline}
                </Heading>
                {message.description && (
                  <Text size="medium" color="text.muted">
                    {message.description}
                  </Text>
                )}
              </Box>
              <Spacer />
              <Box>
                <Button
                  onClick={clearError}
                  variant="primary"
                  className="NavigationButton"
                  marginTop={{ base: 3, md: 8 }}
                >
                  {message.buttonText}
                </Button>
              </Box>
            </Flex>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
