import { ThemeConfig, ThemeDirection, extendTheme } from '@telescope/cassini-ui';
import { breakpoints } from 'utils';
import {
  buttonTheme,
  checkboxTheme,
  containerTheme,
  dividerTheme,
  drawerTheme,
  formLabelTheme,
  headingTheme,
  modalTheme,
  radioTheme,
  textTheme,
} from './components';

export const customTheme = ({ direction = 'ltr' }: { direction: ThemeDirection }): ThemeConfig => {
  return extendTheme({
    direction,
    config: {
      initialColorMode: 'light',
      useSystemColorMode: false,
    },
    colors: {
      woodsmoke: '#141519',
      silverChalice: '#A0A0A0',
      alabaster: '#F8F8F8',
      trout: '#4A4E58',
      silver: '#DADADA',
      jade: '#08B697',
      shark: '#23252B',
      honeyGold: '#FAB818',
      sunset: '#E87953',
      scarpaFlow: '#59595B',
      primaryBase: '#F47521',
      secondaryBlueDark: '#192E38',
      secondaryScooterBase: '#2ABDBB',
      pomegranate: '#EF4323',
      midnight: '#18173A',
      dusk: '#2C325D',
    },
    components: {
      Button: buttonTheme,
      Divider: dividerTheme,
      Drawer: drawerTheme,
      FormLabel: formLabelTheme,
      Heading: headingTheme,
      Text: textTheme,
      Modal: modalTheme,
      Container: containerTheme,
      Checkbox: checkboxTheme,
      Radio: radioTheme,
    },
    fonts: {
      heading: 'Noto Sans, sans-serif',
      body: 'Noto Sans, sans-serif',
      serifHeading: 'Noto Serif, serif',
    },
    styles: {
      global: {
        body: {
          color: 'white',
          lineHeight: 'shorter',
          bg: 'black',
          height: '100%',
        },
        html: {
          height: '100%',
        },
        '#root': {
          height: '100%',
        },
      },
    },
    semanticTokens: {
      colors: {
        Button: {
          primary: {
            bg: 'sunset',
            'bg--hover': '#ff8860',
            'border--hover': 'primaryBase',
            text: 'woodsmoke',
            'text--hover': 'woodsmoke',
            'bg--disabled': 'transparent',
            'border--disabled': 'scarpaFlow',
            'text--disabled': 'scarpaFlow',
          },
          secondary: {
            bg: 'dusk',
            'bg--hover': '#3c437c',
            'border--hover': 'white',
            text: 'white',
            'text--hover': 'white',
          },
        },
        Modal: {
          bg: 'woodsmoke',
          text: 'white',
          link: {
            text: 'sunset',
          },
        },
        HeaderBanner: {
          bg: 'shark',
          text: 'silver',
          link: {
            text: 'silver',
            'text--hover': 'alabaster',
            'bg--hover': 'woodsmoke',
            'bg--default': 'primaryBase',
          },
        },
        NavBanner: {
          bg: 'black',
          text: 'white',
        },
        NavLink: {
          text: 'silver',
          'text--hover': 'alabaster',
          'text--active': 'primaryBase',
        },
        CategoryNavigation: {
          root: {
            bg: 'midnight',
          },
        },
        CategoriesMenu: {
          body: {
            bg: 'dusk',
          },
        },
        NomineeCard: {
          root: {
            bg: 'transparent',
            'bg--hover': 'midnight',
            'bg--active': 'woodsmoke',
            text: 'text.subtle',
            'text--hover': 'woodsmoke',
          },
          image: {
            bg: 'woodsmoke',
          },
          studio: {
            text: 'text.subtle',
          },
        },

        CategoryButton: {
          bg: 'woodsmoke',
          text: 'white',
          'bg--hover': '#23252a',
          'text--hover': 'white',
          'bg--completed': '#06b697',
          'text--completed': 'woodsmoke',
        },
        Footer: {
          bg: 'linear-gradient(#000000, #192E38)',
          text: 'text.subtle',
          'text--strong': 'white',
          'text--hover': 'white',
        },
        text: {
          primary: 'white',
          accent: 'sunset',
          subtle: 'silverChalice',
          muted: 'silver',
          error: 'pomegranate',
        },
        'chakra-border-color': 'scarpaFlow',
      },
    },
    sizes: {
      container: {
        appMax: '1050px',
      },
    },
    breakpoints: breakpoints,
  });
};
