import { checkboxAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/styled-system';
import { mode } from '@chakra-ui/theme-tools';
const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

const baseStyleControl = defineStyle(props => {
  const { colorScheme: c } = props;

  return {
    transitionProperty: 'box-shadow',
    transitionDuration: 'normal',
    border: '2px solid',
    borderRadius: 'sm',
    borderColor: 'silverChalice ',
    color: 'white',

    _checked: {
      bg: 'secondaryBlue',
      borderColor: 'secondaryScooterBase',
      color: 'white',

      _hover: {
        bg: 'secondaryBlue',
        borderColor: 'secondaryScooterBase',
      },

      _disabled: {
        borderColor: mode('gray.200', 'transparent')(props),
        bg: mode('gray.200', 'whiteAlpha.300')(props),
        color: mode('gray.500', 'whiteAlpha.500')(props),
      },
    },

    _invalid: {
      borderColor: 'text.error',
    },
  };
});

const baseStyleLabel = defineStyle({
  fontSize: 'sm',
});

const baseStyle = definePartsStyle(props => ({
  control: baseStyleControl(props),
  label: baseStyleLabel,
}));

const sizes = {
  lg: {
    label: {
      fontSize: 'sm',
    },
  },
};

export const checkboxTheme = defineMultiStyleConfig({
  baseStyle,
  sizes,
  defaultProps: {
    size: 'lg',
  },
});
