export const AUTH_LOCALSTORAGE_KEY = 'AUTH';
export const LAST_VOTE_LOCALSTORAGE_KEY = 'LAST_VOTE';

export const AuthType = {
  LOGIN: 'login',
  REGISTER: 'register',
};

export const QueryKey = {
  VOTE_HISTORY: 'vote-history',
  AUTH_USER: 'auth-user',
} as const;

// eslint-disable-next-line -- intentionally naming the object the same as the type
export type QueryKey = (typeof QueryKey)[keyof typeof QueryKey];

// eslint-disable-next-line -- intentionally naming the object the same as the type
export type AuthType = (typeof AuthType)[keyof typeof AuthType];
