import { Button, Input, InputGroup } from '@telescope/cassini-ui';
import { CountrySelector, usePhoneInput } from 'react-international-phone';
import { UsePhoneInputConfig } from 'react-international-phone/build/hooks/usePhoneInput';
import 'react-international-phone/style.css';

type PhoneNumberFieldProps = Omit<UsePhoneInputConfig, 'onChange'> & {
  onChange: (...event: any[]) => void;
};

export const PhoneNumberField = (props: PhoneNumberFieldProps) => {
  const { onChange, ...rest } = props;
  const phoneInput = usePhoneInput({
    defaultCountry: 'us',
    onChange: data => {
      onChange?.(data.phone);
    },
    ...rest,
  });

  return (
    <InputGroup zIndex={1} dir="ltr">
      <CountrySelector
        selectedCountry={phoneInput.country.iso2}
        onSelect={country => phoneInput.setCountry(country.iso2)}
        renderButtonWrapper={({ children, rootProps }) => (
          <Button rounded={2} {...rootProps} variant="unstyled" px="4px" mr="8px">
            {children}
          </Button>
        )}
      />
      <Input
        variant="flushed"
        placeholder=""
        type="tel"
        color="primary"
        value={phoneInput.inputValue}
        onChange={phoneInput.handlePhoneValueChange}
        ref={phoneInput.inputRef}
        focusBorderColor="primaryBase"
        borderBottomWidth="2px"
      />
    </InputGroup>
  );
};
